var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
export var DEFAULT_BUTTON_AS = "button";
/**
 * Builds the classes needed for a button
 */
var buttonClassBuilder = function (variant, size, disabled, className) {
    return classNames("eds-button", "eds-button--".concat(variant), "eds-button--".concat(size), { "eds-button--disabled": disabled }, className);
};
var ButtonInternals = function (_a) {
    var children = _a.children, beforeSlot = _a.beforeSlot, afterSlot = _a.afterSlot;
    return (_jsxs(_Fragment, { children: [beforeSlot && (_jsx("span", __assign({ className: "eds-button__icon-before" }, { children: beforeSlot }))), children, afterSlot && _jsx("span", __assign({ className: "eds-button__icon-after" }, { children: afterSlot }))] }));
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var Button = function (_a) {
    var as = _a.as, children = _a.children, className = _a.className, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.disableRipple, disableRipple = _c === void 0 ? false : _c, beforeSlot = _a.beforeSlot, afterSlot = _a.afterSlot, _d = _a.size, size = _d === void 0 ? "medium" : _d, _e = _a.type, type = _e === void 0 ? "button" : _e, _f = _a.variant, variant = _f === void 0 ? "primary" : _f, rest = __rest(_a, ["as", "children", "className", "disabled", "disableRipple", "beforeSlot", "afterSlot", "size", "type", "variant"]);
    var ariaDisabled = disabled ? { "aria-disabled": true } : {};
    var Tag = as || DEFAULT_BUTTON_AS;
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _jsxs(Tag, __assign({ disabled: disabled, className: buttonClassBuilder(variant, size, disabled, className), type: type }, ariaDisabled, rest, { children: [_jsx(ButtonInternals, __assign({ afterSlot: afterSlot, beforeSlot: beforeSlot }, { children: children })), !disableRipple && _jsx("span", { className: "eds-button__ripple" })] })));
};
export { ButtonInternals, buttonClassBuilder };
