import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomPanel, Select } from '../../../reusable';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties, isOpen) => (properties.adding
  || !!properties.formErrors.partnerName || isOpen);

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors || prevProps.formData.facilityType !== this.props.formData.facilityType) {
      const { isOpen } = this.state;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props, isOpen) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="Partner"
      onClick={this.panelToggle}
    >
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.formErrors.partnerName}
        id="partnerName"
        isDisabled={this.props.userIsReadOnly}
        label="Partner Name"
        options={Object.keys(this.props.ccmConfig).length > 0 ? this.props.ccmConfig['sls.partnerNameValues'].map((val) => ({ label: val, value: val })) : []}
        value={this.props.formData.partnerName || ''}
        onChange={(value) => this.props.updateFormData('partnerName', value)}
      />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={this.props.userIsReadOnly}
          label="Partner Location ID"
          value={this.props.formData.partnerLocationId || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('partnerLocationId', value)}
        />
      </article>
    </CustomPanel>
  );
}

Partner.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  ccmConfig: PropTypes.shape().isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default Partner;
