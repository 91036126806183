import { Input, Loading } from '@nike/frame-component-library';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React from 'react';

import { parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { putStore, getStoreById } from '../../../utils/service-calls/sls';
import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import {
  announcementValidator, regularHoursValidator, specialHourValidator, storeAddressValidator, storeInfoValidator,
} from '../../../utils/validation/sls-bulk-edit';
import {
  ButtonBlack, ButtonRed, ButtonSubmit, ButtonWhite,
} from '../../reusable';

const defaultArrayChunkSize = 6;
const maxLength = 300;
const maxParseErrors = 100;
const ABSENT_ATTRIBUTE = 'ABSENT_ATTRIBUTE';
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class BulkEditStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // the csv stores
      editStores: [],
      errorMessage: '',
      errors: [],
      failureCount: 0,
      // clears the file selector
      fileComponentKey: Date.now(),
      postingFinished: false,
      postingInProgress: false,
      successCount: 0,
    };
  }

  chunkArray = (arr, size = defaultArrayChunkSize) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

  editStore = async (storeData, errors) => {
    const initialData = await getStoreById(this.props.userToken, storeData.storeId).then((res) => res.body).catch((err) => err);
    // check to make sure this storeId exists in Stores
    const isCmpStore = initialData?.cmp?.id;
    if (initialData?.id !== undefined) {
      let editData = initialData;
      editData = {
        ...editData,
        ...(storeData.storeAddressUpdated && {
          address: {
            ...initialData.address,
            ...{
              address1: storeData.address.address1 !== ABSENT_ATTRIBUTE ? storeData.address.address1 : '',
              address2: storeData.address.address2 !== ABSENT_ATTRIBUTE ? storeData.address.address2 : '',
              address3: storeData.address.address3 !== ABSENT_ATTRIBUTE ? storeData.address.address3 : '',
              area: storeData.address.area !== ABSENT_ATTRIBUTE ? storeData.address.area : '',
              city: storeData.address.city !== ABSENT_ATTRIBUTE ? storeData.address.city : '',
              county: storeData.address.county !== ABSENT_ATTRIBUTE ? storeData.address.county : '',
              postalCode: storeData.address.postalCode !== ABSENT_ATTRIBUTE ? storeData.address.postalCode : '',
              state: storeData.address.state !== ABSENT_ATTRIBUTE ? storeData.address.state : '',
            },
          },
        }),
        ...(storeData.storeInfoUpdated === true && {
          ...{ alternateIds: { hrLocationId: storeData.alternateIds.hrLocationId !== ABSENT_ATTRIBUTE ? storeData.alternateIds.hrLocationId : '' } },
          businessConcept: storeData.businessConcept !== ABSENT_ATTRIBUTE ? storeData.businessConcept : initialData.businessConcept,
          currencies: storeData.currencyCode !== ABSENT_ATTRIBUTE ? [storeData.currencyCode] : initialData.currencies,
          description: storeData.description !== ABSENT_ATTRIBUTE ? storeData.description : initialData.description,
          email: storeData.email !== ABSENT_ATTRIBUTE ? storeData.email : initialData.email,
          facilityType: storeData.facilityType !== ABSENT_ATTRIBUTE ? storeData.facilityType : initialData.facilityType,
          imageUrl: storeData.imageUrl !== ABSENT_ATTRIBUTE ? storeData.imageUrl : initialData.imageUrl,
          locale: storeData.locale !== ABSENT_ATTRIBUTE ? storeData.locale : initialData.locale,
          // update storeDate with all present attributes, not including any absent attributes
          name: storeData.name !== ABSENT_ATTRIBUTE ? storeData.name : initialData.name,
          operationalDetails: {
            closingDate: storeData.operationalDetails.closingDate !== ABSENT_ATTRIBUTE ? storeData.operationalDetails.closingDate : initialData.operationalDetails.closingDate,
            // To account for the scenario where store info and announcement is updated in the same file. If this is not done, existing hours of operation get overwritten.
            // This makes sure hoursOfOperation gets appended to existing value.
            hoursOfOperation: initialData.operationalDetails.hoursOfOperation,
            openingDate: storeData.operationalDetails.openingDate !== ABSENT_ATTRIBUTE ? storeData.operationalDetails.openingDate : initialData.operationalDetails.openingDate,
          },
          partnerName: storeData.partnerName !== ABSENT_ATTRIBUTE ? storeData.partnerName : initialData.partnerName,
          phone: storeData.phone !== ABSENT_ATTRIBUTE ? storeData.phone : initialData.phone,
          ...(!isCmpStore && { shipTo: storeData.shipTo !== ABSENT_ATTRIBUTE ? storeData.shipTo : initialData.shipTo, soldTo: storeData.soldTo !== ABSENT_ATTRIBUTE ? storeData.soldTo : initialData.soldTo }),
          storeStatus: storeData.storeStatus !== ABSENT_ATTRIBUTE ? storeData.storeStatus : initialData.storeStatus,

        }),
        ...(storeData.regularHoursUpdated && {
          operationalDetails: {
            ...initialData.operationalDetails,
            hoursOfOperation: {
              ...initialData.operationalDetails.hoursOfOperation,
              regularHours: storeData.operationalDetails.hoursOfOperation.regularHours,
            },
          },
        }),
      };

      storeData.localizations.forEach((localization) => {
        // Making sure localization object is existing before processing data
        if (!editData.localizations) {
          editData.localizations = [];
        }
        const localizationIndex = editData.localizations.findIndex((x) => x.language === localization.language);
        if (localizationIndex !== -1) {
          localization.announcements.forEach((announcement) => {
            if (editData.localizations[localizationIndex].announcements) {
              editData.localizations[localizationIndex].announcements.push(announcement);
            } else {
              editData.localizations[localizationIndex].announcements = [announcement];
            }
          });
        } else {
          // SLS gives a 500 error if address is not set
          editData.localizations.push({
            address: {
              address1: '',
              city: '',
              country: '',
              postalCode: '',
              state: '',
            },
            announcements: localization.announcements,
            language: localization.language,
          });
        }
      });
      storeData.operationalDetails.hoursOfOperation.specialHours.forEach((specialHour) => {
        const specialHourSanitized = {
          date: specialHour.date,
          hours: specialHour.hours,
        };
        const specialHoursToAdd = specialHour.closedAllDay === 'NORMAL' ? [] : [specialHourSanitized];
        const doesNotMatchDateOfNewSpecialHour = ({ date }) => date !== specialHour.date;
        const existingSpecialHoursWithoutAnyWithTheSameDateAsTheNewOne = (editData.operationalDetails.hoursOfOperation.specialHours ?? [])
          .filter(doesNotMatchDateOfNewSpecialHour);
        const newSpecialHours = [...existingSpecialHoursWithoutAnyWithTheSameDateAsTheNewOne, ...specialHoursToAdd];
        editData.operationalDetails.hoursOfOperation.specialHours = newSpecialHours;
      });

      await putStore(this.props.userToken, { new: editData, old: initialData })
        .then(() => this.setState((prevState) => ({ successCount: prevState.successCount + 1 })))
        .catch((error) => {
          const err = parseSLSandBrickworksErrors(error, true);
          errors.push({ message: `${storeData.storeId},${err.message}` });
          return this.setState((prevState) => ({ failureCount: prevState.failureCount + 1 }));
        });
    } else {
      errors.push({ message: `${storeData.storeId}, ${initialData.statusCode === 404 ? 'This Store ID does not exist' : initialData.message}` });
      this.setState((prevState) => ({ failureCount: prevState.failureCount + 1 }));
    }
  };

  exportExampleCsv = () => {
    const csvBodyBuilder = (lines) => `data:text/csv;charset=utf-8,${lines.map((line) => line.map((text) => `"${text}"`).join()).join('\r\n')}`;
    const csv = document.createElement('a');
    const exampleLines = [
      ['ANNOUNCEMENT', '{Replace this cell with storeId}', '{2 letter language code, eg: en}', '{Message Type - ALERT or PROMOTION}', '{Expiration Date, M/D/YY}","{Content of Announcement}'],
      ['SPECIAL_HOURS', '{Replace this cell with storeId}', '{Date, M/D/YY}","{YES=Closed All Day, NO=Special Hours, or NORMAL=Regular Hours}', '{Local Open Time, HH:MM (24 Hour), Required for Special Hours, Blank for Regular Hours & Closed All Day}', '{Local Closed Time, HH:MM (24 Hour), Required for Special Hours, Blank for Regular Hours & Closed All Day}'],
      ['STORE_INFO', '{Replace this cell with storeId}', '{Opening Date, M/D/YY}","{Closing Date, M/D/YY}', '{Store Status, OPEN, CLOSED, or UNOPENED}', '{Locale, Language-Country code, eg. en-US}', '{currency (3 character abbrev, eg USD)}', '{Business Concept}', '{email}', '{phone}', '{Partner Name}', '{Store Name}', '{Description}', '{Facility Type}', '{Image Url}', '{Ship To}', '{Sold To}', '{HR Location ID}'],
      ['REGULAR_HOURS', '{Replace this cell with storeId}', ...days.flatMap((day) => [`{${day} closed all day? YES or NO}`, `{${day} Local Open Time, HH:MM (24 Hour), if Closed all day==CLOSED`, `{${day} Local Closed Time, HH:MM (24 Hour), Required if Closed all day==CLOSED}`])],
      ['STORE_ADDRESS', '{Replace this cell with storeId}', '{address1}', '{address2}', '{address3}', '{postalCode}', '{city}', '{state}', '{county}', '{area}'],
    ].flatMap((line) => [line, line]); // duplicate each line
    csv.href = csvBodyBuilder(exampleLines);
    csv.target = '_blank';
    csv.download = 'bulk-edit-template.csv';
    csv.click();
  };

  exportFailuresToCsv = () => {
    const csv = document.createElement('a');
    let csvContent = '';
    this.state.errors.forEach((error) => {
      csvContent += `${error.message}\r\n`;
    });
    csv.href = `data:text/csv;charset=utf-8,${encodeURI('Store Id,Failure Reason')}\r\n${encodeURI(csvContent)}`;
    csv.target = '_blank';
    csv.download = 'failures.csv';
    csv.click();
  };

  getErrorMessage = (
    { errors },
    hasTooManyErrorsToDisplay = errors.length > maxParseErrors,
    errorsToDisplay = hasTooManyErrorsToDisplay
      ? errors.slice(0, maxParseErrors)
      : errors,
    errorMessagesToDisplay = errorsToDisplay.map(({ message }) => message),
    getRemainingErrorCount = () => errors.length - maxParseErrors,
    remainingErrorTokens = hasTooManyErrorsToDisplay
      ? [`(${getRemainingErrorCount()} more errors)...`]
      : [],
    errorMessage = [...errorMessagesToDisplay, ...remainingErrorTokens].join(', '),
  ) => errorMessage;

  getPercentComplete = () => Math.floor(((this.state.successCount + this.state.failureCount) * 100) / this.state.editStores.length);

  getStore = (id, stores) => stores.find((store) => store.id === id) || {};

  handleFile = (event) => {
    const file = event.target.files[0];
    const results = { editStores: [], errors: [] };
    let tooManyRows = '';
    Papa.parse(file, {
      beforeFirstChunk: (parsedFile) => {
        const csvLength = parsedFile.split('\n').length;
        if (csvLength > maxLength) {
          tooManyRows = `File contains ${csvLength} rows, which is more than the maximum: ${maxLength}`;
        }
      },
      chunk: (partialResults, parser) => (tooManyRows ? this.setState({ editStores: [], errorMessage: tooManyRows, errors: [] }) : this.onParseChunk(partialResults, parser, results)),
      comments: true,
      complete: (newFile) => (tooManyRows ? this.setState({ editStores: [], errorMessage: tooManyRows, errors: [] }) : this.onParseComplete(newFile, results)),
      error: (e) => this.setState({ editStores: [], errorMessage: e, errors: [] }),
      header: false,
    });
  };

  // This function merges the processed data from excel at store level.
  // If there are multiple announcements or special hours present for a store, this function combines them and create one single store object
  // Output of this function would be a unique store list which would be iterated and submitted to SLS on button click.
  mashData = (stores) => {
    const uniqueStoreList = [];
    const uniqueStoreIds = [...new Set(stores.map((store) => store.storeId))];
    uniqueStoreIds.forEach((storeId) => {
      const mashedData = {
        localizations: [],
        operationalDetails: {
          hoursOfOperation: {
            regularHours: {
              friday: [],
              monday: [],
              saturday: [],
              sunday: [],
              thursday: [],
              tuesday: [],
              wednesday: [],
            },
            specialHours: [],
          },
        },
      };
      mashedData.storeId = storeId;
      const storeData = stores.filter((a) => a.storeId === storeId);
      storeData.forEach((b) => {
        if (b.updateType === 'ANNOUNCEMENT') {
          mashedData.announcementUpdated = true;
          const announcement = { content: b.content, expiryDate: b.expirationDate, messageType: b.messageType };
          const languageIndex = mashedData.localizations.findIndex((x) => x.language === b.language);
          if (languageIndex !== -1) {
            mashedData.localizations[languageIndex].announcements.push(announcement);
          } else {
            mashedData.localizations.push({ announcements: [announcement], language: b.language });
          }
        }
        if (b.updateType === 'REGULAR_HOURS') {
          mashedData.regularHoursUpdated = true;
          mashedData.operationalDetails.hoursOfOperation.regularHours = days.reduce((regularHours, day) => ({ ...regularHours, [day]: (b[`${day}ClosedAllDay`] === 'YES' ? [] : [{ localCloseTime: b[`${day}CloseTime`], localOpenTime: b[`${day}OpenTime`] }]) }), {});
        }
        if (b.updateType === 'SPECIAL_HOURS') {
          mashedData.specialHoursUpdated = true;
          const specialHour = { closedAllDay: b.closedAllDay, date: b.date };
          if (b.closedAllDay === 'NO') {
            specialHour.hours = [];
            specialHour.hours.push({ localCloseTime: b.localCloseTime, localOpenTime: b.localOpenTime });
          }
          mashedData.operationalDetails.hoursOfOperation.specialHours.push(specialHour);
        }
        if (b.updateType === 'STORE_ADDRESS') {
          mashedData.storeAddressUpdated = true;
          mashedData.address = {
            address1: b.address1,
            address2: b.address2,
            address3: b.address3,
            area: b.area,
            city: b.city,
            county: b.county,
            postalCode: b.postalCode,
            state: b.state,
          };
        }
        if (b.updateType === 'STORE_INFO') {
          // Data validation needs to be completed before execution reach here.
          // If duplicate store info is present for a store even after validation, latest one will be picked.
          mashedData.storeInfoUpdated = true;
          mashedData.operationalDetails.openingDate = b.openingDate;
          mashedData.operationalDetails.closingDate = b.closingDate;
          mashedData.storeStatus = b.storeStatus;
          mashedData.locale = b.locale;
          mashedData.currencyCode = b.currencyCode;
          mashedData.businessConcept = b.businessConcept;
          mashedData.email = b.email;
          mashedData.phone = b.phone;
          mashedData.partnerName = b.partnerName;
          mashedData.name = b.name;
          mashedData.description = b.description;
          mashedData.facilityType = b.facilityType;
          mashedData.imageUrl = b.imageUrl;
          mashedData.shipTo = b.shipTo;
          mashedData.soldTo = b.soldTo;
          mashedData.alternateIds = {
            hrLocationId: b.hrLocationId,
          };
        }
      });
      uniqueStoreList.push(mashedData);
    });
    return uniqueStoreList;
  }

  onClear = () => this.setState({
    // the csv stores
    editStores: [],
    errorMessage: '',
    errors: [],
    failureCount: 0,
    // clears the file selector
    fileComponentKey: Date.now(),
    postingFinished: false,
    postingInProgress: false,
    successCount: 0,
  });

  onParseChunk = (partialResults, parser, results) => {
    let i = 0;
    partialResults.data.forEach((result) => {
      i++;
      switch (result[0]) {
        case 'ANNOUNCEMENT':
          this.processAnnouncement(result, i, results);
          break;
        case 'REGULAR_HOURS':
          this.processRegularHour(result, i, results);
          break;
        case 'SPECIAL_HOURS':
          this.processSpecialHour(result, i, results);
          break;
        case 'STORE_ADDRESS':
          this.processStoreAddress(result, i, results);
          break;
        case 'STORE_INFO':
          this.processStoreInfo(result, i, results);
          break;
        case '':
          // Ignore unnamed update types (if people delete rows, we don't want to continue pushing errors)
          break;
        default:
          // Unknown update type
          results.errors.push({ message: `Row ${i} has an invalid updateType - ${result[0]}` });
      }
      if (this.getErrorMessage(results)) {
        parser.abort();
      }
    });
  };

  onParseComplete = (file, results) => {
    const errorMessage = this.getErrorMessage(results);
    if (!errorMessage) {
      this.setState({ editStores: this.mashData(results.editStores), errorMessage: '', errors: [] });
    } else {
      this.setState({ editStores: [], errorMessage, errors: [] });
    }
  };

  onSubmit = () => this.setState({ postingFinished: false, postingInProgress: true },
    () => this.setState((prevState) => {
      this.chunkArray(prevState.editStores).reduce(async (previousPromise, currentStoreChunk) => {
        await previousPromise;
        const results = await this.updateStores(prevState, currentStoreChunk);
        return Promise.all(results);
      }, Promise.resolve())
        .finally(() => this.setState({ errors: prevState.errors, postingFinished: true, postingInProgress: false }))
        .catch((err) => this.setState({ errorMessage: err }));
    }));

  processAnnouncement = (rawData, index, results) => {
    const announcementKeys = ['updateType', 'storeId', 'language', 'messageType', 'expirationDate', 'content'];
    const extractedData = announcementKeys.reduce((acc, cur, i) => ({ ...acc, [cur]: rawData[i] }), {});
    const timeZone = this.getStore(extractedData.storeId, this.props.facilities).timezone;
    const validatorResult = announcementValidator(extractedData, index, timeZone);
    if (validatorResult.errors.length > 0) {
      validatorResult.errors.forEach((error) => results.errors.push(error));
    } else {
      results.editStores.push(validatorResult.validatedAnnouncement);
    }
  };

  processRegularHour = (rawData, index, results) => {
    const regularHoursKey = [
      'updateType',
      'storeId',
      ...days.flatMap((day) => [
        `${day}ClosedAllDay`, `${day}OpenTime`, `${day}CloseTime`,
      ]),
    ];
    const extractedData = regularHoursKey.reduce((acc, cur, i) => ({ ...acc, [cur]: rawData[i] }), {});
    const validatorResult = regularHoursValidator(extractedData, index);
    if (validatorResult.errors.length > 0) {
      validatorResult.errors.forEach((error) => results.errors.push(error));
    } else {
      results.editStores.push(validatorResult.validatedRegularHour);
    }
  }

  processSpecialHour = (rawData, index, results) => {
    const [updateType, storeId, ...restOfData] = rawData;
    const extractData = (data, previouslyExtracted = []) => {
      const [date, closedAllDay, localOpenTime, localCloseTime, ...remainingData] = data;
      const extractedData = {
        closedAllDay, date, localCloseTime, localOpenTime, storeId, updateType,
      };
      return remainingData.length === 0
        ? [...previouslyExtracted, extractedData]
        : extractData(remainingData, [...previouslyExtracted, extractedData]);
    };
    const extractedDataArray = extractData(restOfData);
    extractedDataArray.forEach((extractedData) => {
      const validatorResult = specialHourValidator(extractedData, index);
      if (validatorResult.errors.length > 0) {
        validatorResult.errors.forEach((error) => results.errors.push(error));
      } else {
        results.editStores.push(validatorResult.validatedSpecialHour);
      }
    });
  };

  processStoreAddress = (rawData, index, results) => {
    const addressKeys = ['updateType', 'storeId', 'address1', 'address2', 'address3', 'postalCode', 'city', 'state', 'county', 'area'];
    const extractedData = addressKeys.reduce((acc, cur, i) => ({ ...acc, [cur]: rawData[i] }), {});
    const validatorResult = storeAddressValidator(extractedData, index, results.editStores);
    if (validatorResult.errors.length > 0) {
      validatorResult.errors.forEach((error) => results.errors.push(error));
    } else {
      results.editStores.push(validatorResult.validatedAddressData);
    }
  }

  processStoreInfo = (rawData, index, results) => {
    const storeKeys = [
      'updateType',
      'storeId',
      'openingDate',
      'closingDate',
      'storeStatus',
      'locale',
      'currencyCode',
      'businessConcept',
      'email',
      'phone',
      'partnerName',
      'name',
      'description',
      'facilityType',
      'imageUrl',
      'shipTo',
      'soldTo',
      'hrLocationId',
    ];
    const extractedData = storeKeys.reduce((acc, cur, i) => ({ ...acc, [cur]: rawData[i] }), {});
    const validatorResult = storeInfoValidator(extractedData, index, results.editStores, this.props.ccmConfig);
    if (validatorResult.errors.length > 0) {
      validatorResult.errors.forEach((error) => results.errors.push(error));
    } else {
      results.editStores.push(validatorResult.validatedStoreData);
    }
  };

  updateStores = async (prevState, stores) => stores.map(async (store) => this.editStore(store, prevState.errors));

  render = () => (
    <>
      {this.state.postingInProgress && (
        <aside className="ncss-col-sm-12 ta-sm-c">
          <Loading />
          <p>Please do not navigate away from this page while the stores are updating. If you do, your requests may not go through.</p>
        </aside>
      )}
      <section className="ncss-row pb6-sm va-sm-t">
        <article className="ncss-col-lg-3 ncss-col-sm-12">
          <h1 className="text-color-accent body-1 mt2-sm mb-2-sm">Bulk Edit Stores</h1>
          <ButtonBlack
            className="mt3-sm"
            label="Download Template CSV"
            onClick={this.exportExampleCsv}
          />
        </article>
        <article className="ncss-col-lg-3 ncss-col-sm-9 va-sm-t mt3-sm">
          <Input
            key={this.state.fileComponentKey}
            accept=".csv"
            datatype="text"
            errorMessage={this.state.errorMessage || (!this.state.editStores.length ? REQUIRED_FIELD : '')}
            id="fileEditStore"
            label="File Upload"
            type="file"
            onChange={this.handleFile}
          />
        </article>
        <article className="ncss-col-lg-2 ncss-col-sm-3 va-sm-t">
          <ButtonWhite
            isDisabled={this.state.postingInProgress}
            label="Clear"
            onClick={this.onClear}
          />
        </article>
        <article className="ncss-col-lg-4 ncss-col-sm-12 va-sm-t ta-sm-c">
          {this.state.postingInProgress || this.state.postingFinished
            ? (
              <>
                <header className="mt8-sm">Results</header>
                <section className="ncss-row ta-sm-c">
                  <article className="ncss-col-sm-5 va-sm-m ta-sm-r">{`${this.getPercentComplete()}% done`}</article>
                  <article className="ncss-col-sm-7 va-sm-m ta-sm-l">
                    <p className="text-color-success">{`${this.state.successCount} edits made`}</p>
                    <p className="text-color-error">{`${this.state.failureCount} edits failed to complete`}</p>
                  </article>
                </section>
                {this.state.postingFinished && (this.state.failureCount === 0 // 100% success
                  ? (
                    <article className="ncss-col-sm-12 mt2-sm text-color-success ta-sm-c">
                      Your changes were successful.
                    </article>
                  )
                  : (
                    <article className="ncss-col-sm-12 mt2-sm va-sm-b ta-sm-c">
                      <ButtonRed
                        className=""
                        confirm={false}
                        label="Download Failures"
                        onClick={this.exportFailuresToCsv}
                      />
                      <p className="va-sm-t ta-sm-c text-color-accent body-4">A Brickwork error indicates a successful write to Stores.</p>
                    </article>
                  )
                )}
              </>
            )
            : (
              <ButtonSubmit
                className="va-sm-t"
                isDisabled={this.state.editStores.length === 0 || !!this.state.errorMessage}
                label="Bulk Edit Stores"
                onClick={this.onSubmit}
              />
            )}
        </article>
      </section>
    </>
  );
}

BulkEditStores.propTypes = {
  ccmConfig: PropTypes.shape().isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  userToken: PropTypes.string.isRequired,
};

export default BulkEditStores;
