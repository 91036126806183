var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState, useEffect } from "react";
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
// @todo Revisit this for version 2 of the library
/**
 * This hook tracks a set of id's related to checkboxes logic
 * @param {string[]} inputIDs, array of element ids to track
 * @returns {IndeterminateHookReturnType}
 */
export var useIndeterminateCheckbox = function (inputIDs) {
    var _a = useState({
        value: false,
        id: "",
        action: "",
    }), oneChecked = _a[0], setOneChecked = _a[1];
    var _b = useState(), multiChecked = _b[0], setMultiChecked = _b[1];
    var _c = useState(false), allChecked = _c[0], setAllChecked = _c[1];
    var _d = useState([]), checkedArr = _d[0], setCheckedArr = _d[1];
    useEffect(function () {
        if (allChecked && checkedArr.length !== inputIDs.length) {
            var newArr_1 = [];
            inputIDs === null || inputIDs === void 0 ? void 0 : inputIDs.forEach(function (row) {
                newArr_1.push(row.id);
            });
            setCheckedArr(newArr_1);
        }
        if (!allChecked && checkedArr.length === inputIDs.length) {
            setCheckedArr([]);
        }
    }, [allChecked, checkedArr, inputIDs]);
    useEffect(function () {
        if (oneChecked === null || oneChecked === void 0 ? void 0 : oneChecked.value) {
            if ((oneChecked === null || oneChecked === void 0 ? void 0 : oneChecked.action) === "add") {
                setCheckedArr(__spreadArray(__spreadArray([], checkedArr, true), [oneChecked === null || oneChecked === void 0 ? void 0 : oneChecked.id], false));
                setOneChecked({ value: false, id: "", action: "" });
            }
            if ((oneChecked === null || oneChecked === void 0 ? void 0 : oneChecked.action) === "remove") {
                var newArr = checkedArr.filter(function (id) { return id !== oneChecked.id; });
                setAllChecked(false);
                setOneChecked({ value: false, id: "", action: "" });
                setCheckedArr(newArr);
            }
        }
    }, [oneChecked, checkedArr]);
    useEffect(function () {
        if (checkedArr.length === inputIDs.length) {
            setAllChecked(true);
        }
    }, [checkedArr]);
    useEffect(function () {
        if ((multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.action) === "add") {
            if (typeof (multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.ids) === "string") {
                setCheckedArr(uniq(merge(checkedArr, multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.ids.split(" "))));
                setMultiChecked(undefined);
            }
            else if (typeof (multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.ids) === "object") {
                setCheckedArr(uniq(merge(checkedArr, multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.ids)));
                setMultiChecked(undefined);
            }
        }
        else if ((multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.action) === "remove") {
            setCheckedArr(remove(checkedArr, function (id) { return !(multiChecked === null || multiChecked === void 0 ? void 0 : multiChecked.ids.includes(id)); }));
            setMultiChecked(undefined);
        }
    }, [multiChecked]);
    return {
        allChecked: allChecked,
        setAllChecked: setAllChecked,
        checkedArr: checkedArr,
        setOneChecked: setOneChecked,
        setMultiChecked: setMultiChecked,
    };
};
