function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var concat = Array.prototype.concat;
export var flatten = function flatten(array) {
  return concat.apply([], array);
};
export var throwInvariant = function throwInvariant(condition, message) {
  if (!condition) {
    throw Error(message);
  }
};
export var isPromiseLike = function isPromiseLike(obj) {
  return !!obj && _typeof(obj) === 'object' && typeof obj.then === 'function';
};