function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { loop, isLoop, getModel, getCmd } from './loop';
import Cmd from './cmd';
export default function mergeChildReducers(parentResult, action, childMap) {
  for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    args[_key - 3] = arguments[_key];
  }

  var initialState = parentResult,
      parentCmd;

  if (isLoop(initialState)) {
    parentCmd = getCmd(initialState);
    initialState = getModel(initialState);
  }

  var cmds = parentCmd ? [parentCmd] : [];
  var hasChanged = false;
  var newState = Object.keys(childMap).reduce(function (prev, key) {
    var childReducer = childMap[key];

    if (!childReducer) {
      if (!hasChanged) {
        prev = _objectSpread({}, prev);
        hasChanged = true;
      }

      delete prev[key];
      return prev;
    }

    var currentChild = childReducer.apply(void 0, [prev[key], action].concat(args));

    if (isLoop(currentChild)) {
      cmds.push(getCmd(currentChild));
      currentChild = getModel(currentChild);
    }

    if (prev[key] !== currentChild && hasChanged) {
      prev[key] = currentChild;
    } else if (prev[key] !== currentChild) {
      prev = _objectSpread({}, prev, _defineProperty({}, key, currentChild));
      hasChanged = true;
    }

    return prev;
  }, initialState);
  return loop(newState, batchCmds(cmds));
}
export function batchCmds(cmds) {
  switch (cmds.length) {
    case 0:
      return Cmd.none;

    case 1:
      return cmds[0];

    default:
      return Cmd.list(cmds);
  }
}