var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { Text } from "../Text/Text";
export var AppHeader = function (_a) {
    var appName = _a.appName, logoSlot = _a.logoSlot, avatarSlot = _a.avatarSlot, toolbarSlot = _a.toolbarSlot, commandBarSlot = _a.commandBarSlot, isDark = _a.isDark, className = _a.className, rest = __rest(_a, ["appName", "logoSlot", "avatarSlot", "toolbarSlot", "commandBarSlot", "isDark", "className"]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classNames("eds-app-header", {
                    "eds-app-header--dark eds--dark": isDark,
                }, className) }, rest, { children: [logoSlot, typeof appName === "string" ? (_jsx(Text, __assign({ as: "h1", font: "subtitle-1", className: "eds-app-header__name" }, { children: appName }))) : (_jsx("span", __assign({ className: "eds-app-header__name" }, { children: appName }))), _jsx("span", { className: "eds-app-header__spacer" }), toolbarSlot, avatarSlot] })), commandBarSlot && (_jsx("div", __assign({ className: classNames("eds-command-bar", {
                    "eds-command-bar--dark eds--dark": isDark,
                }) }, { children: commandBarSlot })))] }));
};
