var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
export var Label = function (_a) {
    var _b;
    var className = _a.className, screenReaderOnly = _a.screenReaderOnly, font = _a.font, color = _a.color, hasErrors = _a.hasErrors, children = _a.children, rest = __rest(_a, ["className", "screenReaderOnly", "font", "color", "hasErrors", "children"]);
    return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    _jsxs("label", __assign({ className: classNames("eds-label", (_b = {},
            _b["eds-color--".concat(color)] = color,
            _b["eds-type--".concat(font)] = font,
            _b["eds-hidden--screen-reader-only"] = screenReaderOnly,
            _b), className) }, rest, { children: [children, hasErrors && (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: "eds-color--text-danger", "aria-hidden": true }, { children: "*" })), _jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: " Error" }))] }))] })));
};
