import mergeChildReducers from './merge-child-reducers';
export default function combineReducers(childMap) {
  return function () {
    var rootState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;

    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }

    return mergeChildReducers.apply(void 0, [rootState, action, childMap].concat(args));
  };
}