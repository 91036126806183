var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { CheckboxIcon } from "@nike/nike-design-system-icons";
import classNames from "classnames";
// The Minus icon in NDS is a little too narrow, so I combined the Minus Icon and Checkbox Icon to get the same functionality here
var IndeterminateIcon = function (_a) {
    var className = _a.className;
    return (_jsx("svg", __assign({ "aria-hidden": "true", className: className, focusable: "false", viewBox: "0 0 24 24", role: "img", width: "24px", height: "24px", fill: "none" }, { children: _jsx("g", __assign({ stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" }, { children: _jsxs("g", __assign({ transform: "translate(2.000000, 2.000000)" }, { children: [_jsx("rect", { x: "0", y: "0", width: "20", height: "20", rx: "10" }), _jsx("rect", { className: "checkbox-indeterminate", x: "0", y: "0", width: "20", height: "20", rx: "5" }), _jsx("path", { className: "checkicon-check", strokeWidth: "2", d: "M15 10H5", strokeLinecap: "round", strokeLinejoin: "round" })] })) })) })));
};
export var Checkbox = function (_a) {
    var _b = _a.checked, checked = _b === void 0 ? false : _b, className = _a.className, _c = _a.disabled, disabled = _c === void 0 ? false : _c, indeterminate = _a.indeterminate, id = _a.id, label = _a.label, hideLabel = _a.hideLabel, name = _a.name, onChange = _a.onChange, value = _a.value, rest = __rest(_a, ["checked", "className", "disabled", "indeterminate", "id", "label", "hideLabel", "name", "onChange", "value"]);
    // @todo focus doesn't work
    var ref = React.useRef();
    var _d = useState(!!checked), isChecked = _d[0], setIsChecked = _d[1];
    // Side effect: Toogle indeterminate state of DOM based on React attribute
    useEffect(function () {
        if (ref.current) {
            ref.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);
    // used to make a controlled component
    useEffect(function () {
        setIsChecked(!!checked);
    }, [checked]);
    var handleChange = function (event) {
        if (disabled !== true) {
            setIsChecked(!isChecked);
            if (onChange)
                onChange(event);
        }
    };
    var containerClass = classNames(className, "eds-checkbox", {
        "eds-checkbox--disabled": disabled,
    });
    return (_jsxs("label", __assign({ htmlFor: id, className: containerClass }, { children: [_jsx("input", __assign({ ref: ref, type: "checkbox", name: name, id: id, value: value || id, checked: isChecked, disabled: disabled }, (indeterminate ? { "aria-checked": "mixed" } : {}), { className: "eds-checkbox__input", onChange: handleChange }, rest)), _jsx("span", { className: "eds-checkbox__box circle" }), _jsx("span", { className: "eds-checkbox__box" }), indeterminate ? (_jsx(IndeterminateIcon, { className: "eds-checkbox__icon-container" })) : (_jsx(CheckboxIcon, { className: "eds-checkbox__icon-container" })), hideLabel ? (_jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: label }))) : (label)] })));
};
