var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
export var DEFAULT_CHIP_AS = "span";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var Chip = function (_a) {
    var as = _a.as, disabled = _a.disabled, _b = _a.size, size = _b === void 0 ? "medium" : _b, beforeSlot = _a.beforeSlot, afterSlot = _a.afterSlot, className = _a.className, hasBorder = _a.hasBorder, children = _a.children, rest = __rest(_a, ["as", "disabled", "size", "beforeSlot", "afterSlot", "className", "hasBorder", "children"]);
    var Tag = as || DEFAULT_CHIP_AS;
    return (_jsxs(Tag, __assign({ className: classNames("eds-chip", "eds-chip--".concat(size), { "eds-chip--disabled": disabled, "eds-chip--border": hasBorder }, className) }, { disabled: disabled }, rest, { children: [beforeSlot, children, afterSlot] })));
};
