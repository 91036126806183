var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from "react";
function useOnClickOutside(_a) {
    var ref = _a.ref, _b = _a.ignoreRefs, ignoreRefs = _b === void 0 ? [] : _b, isActive = _a.isActive, handler = _a.handler, dep = _a.dep;
    useEffect(function () {
        var listener = function (event) {
            var el = (ref === null || ref === void 0 ? void 0 : ref.current) ? ref.current : ref;
            // Do nothing if clicking ref's element or descendent elements
            if (!el ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                el.contains(event.target) ||
                ignoreRefs.find(function (ignoreRef) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return ignoreRef.contains(event.target);
                }) ||
                !isActive) {
                return;
            }
            handler === null || handler === void 0 ? void 0 : handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
        // Reload only if ref or handler changes
    }, __spreadArray([ref, handler, isActive, ignoreRefs], dep, true));
}
export default useOnClickOutside;
