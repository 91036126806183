/**
 * Displays a warning in the console if any deprecated props are used in a given props object
 * @param componentName
 * @param props
 * @param deprecatedProps array of strings of the prop names that are deprecated
 */
export var deprecationPropWarning = function (componentName, props, deprecatedProps) {
    deprecatedProps.forEach(function (prop) {
        if (props[prop]) {
            // eslint-disable-next-line no-console
            console.warn("EDS Warning: the \"".concat(String(prop), "\" property on ").concat(componentName, " component is deprecated, and will be removed in future releases."));
        }
    });
};
