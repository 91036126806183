var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
export var Tab = function (_a) {
    var active = _a.active, disabled = _a.disabled, id = _a.id, children = _a.children, name = _a.name, onChange = _a.onChange, value = _a.value, className = _a.className, rest = __rest(_a, ["active", "disabled", "id", "children", "name", "onChange", "value", "className"]);
    var activeCasted = !!active;
    var _b = React.useState(activeCasted), isChecked = _b[0], setIsChecked = _b[1];
    var classes = classNames(className, "eds-tab", {
        "eds-tab--active": isChecked,
    });
    // used to make a controlled component
    React.useEffect(function () {
        setIsChecked(activeCasted);
    }, [activeCasted]);
    var handleChange = function (event) {
        if (disabled !== true) {
            setIsChecked(!isChecked);
            if (onChange)
                onChange(event);
        }
    };
    return (_jsx("li", __assign({ role: "tab" }, { children: _jsxs("label", __assign({ htmlFor: id, className: classes }, { children: [_jsx("input", __assign({ type: "radio", name: name, id: id, value: value || id, checked: activeCasted, disabled: !!disabled, onChange: handleChange }, rest)), _jsx("div", __assign({ className: "eds-tab__focus-ring" }, { children: children }))] })) })));
};
