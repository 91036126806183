var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var IconButton = function (_a) {
    var className = _a.className, icon = _a.icon, label = _a.label, size = _a.size, count = _a.count, rest = __rest(_a, ["className", "icon", "label", "size", "count"]);
    var iconRender = typeof icon === "string" ? _jsx(Icon, { size: "m", name: icon }) : icon;
    return (_jsxs("div", __assign({ className: "eds-button--icon__wrapper" }, { children: [!!count && _jsx("span", __assign({ className: "eds-button--icon__count" }, { children: count })), _jsxs(Button, __assign({ size: size, className: classNames("eds-button--icon", className) }, rest, { children: [iconRender, _jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: label }))] }))] })));
};
