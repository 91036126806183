var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Button } from "../Button/Button";
import { TBGContext } from "../ToggleButtonGroup/ToggleButtonGroup";
var DEFAULT_TOGGLE_BUTTON_AS = "button";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var ToggleButton = function (_a) {
    var children = _a.children, active = _a.active, rest = __rest(_a, ["children", "active"]);
    var groupContext = React.useContext(TBGContext);
    return (_jsx(Button, __assign({ size: (groupContext === null || groupContext === void 0 ? void 0 : groupContext.size) || undefined, variant: active ? "primary" : "secondary", className: classNames("eds-toggle-button") }, rest, { children: children })));
};
