var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { InputMessageGroup } from "../InputMessageGroup/InputMessageGroup";
import { Label } from "../Label/Label";
export var TextField = React.forwardRef(function (_a, ref) {
    var beforeSlot = _a.beforeSlot, afterSlot = _a.afterSlot, _b = _a.noBorder, noBorder = _b === void 0 ? false : _b, onChange = _a.onChange, value = _a.value, maxChars = _a.maxChars, errorMessage = _a.errorMessage, id = _a.id, style = _a.style, label = _a.label, subtitle = _a.subtitle, hasErrors = _a.hasErrors, _c = _a.hideLabel, hideLabel = _c === void 0 ? false : _c, message = _a.message, _d = _a.readOnlyText, readOnlyText = _d === void 0 ? "Read-only" : _d, _e = _a.type, type = _e === void 0 ? "text" : _e, readOnly = _a.readOnly, className = _a.className, defaultValue = _a.defaultValue, rest = __rest(_a, ["beforeSlot", "afterSlot", "noBorder", "onChange", "value", "maxChars", "errorMessage", "id", "style", "label", "subtitle", "hasErrors", "hideLabel", "message", "readOnlyText", "type", "readOnly", "className", "defaultValue"]);
    var _f = React.useState(defaultValue || value), localInputValue = _f[0], setLocalInputValue = _f[1];
    var handleChange = function (e) {
        setLocalInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };
    React.useEffect(function () {
        setLocalInputValue(value);
    }, [value]);
    var _g = React.useState(0), beforeSlotWidth = _g[0], setBeforeSlotWidth = _g[1];
    var _h = React.useState(0), afterSlotWidth = _h[0], setAfterSlotWidth = _h[1];
    var beforeSlotOffset = beforeSlotWidth ? 24 : 16;
    var afterSlotOffset = afterSlotWidth ? 24 : 16;
    var paddingLeft = beforeSlotWidth
        ? beforeSlotWidth + beforeSlotOffset
        : beforeSlotOffset;
    var paddingRight = afterSlotWidth
        ? afterSlotWidth + afterSlotOffset
        : afterSlotOffset;
    // Spreading this object on the input element allows for support for
    // both controlled and uncontrolled approaches
    var valueSpreadMapping = value === undefined ? { defaultValue: defaultValue } : { value: value };
    return (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: id, font: "title-6", screenReaderOnly: hideLabel, hasErrors: hasErrors }, { children: label })), subtitle && (_jsx(Label, __assign({ htmlFor: id, font: "body-3", color: "text-secondary" }, { children: subtitle }))), _jsxs("div", __assign({ className: classNames("eds-text-field", className) }, { children: [beforeSlot && (_jsx("div", __assign({ className: "eds-text-field__before", ref: function (el) { return setBeforeSlotWidth(el === null || el === void 0 ? void 0 : el.getBoundingClientRect().width); } }, { children: beforeSlot }))), _jsx("input", __assign({ className: classNames("eds-text-field__input", {
                            "eds-text-field__input--error": hasErrors,
                            "eds-text-field__input--no-border": noBorder === true,
                        }), style: __assign({ paddingLeft: paddingLeft, paddingRight: paddingRight }, style), ref: ref, onChange: handleChange, id: id, type: type, "aria-describedby": "".concat(id, "-meta"), readOnly: readOnly }, valueSpreadMapping, rest)), afterSlot && (_jsx("div", __assign({ className: "eds-text-field__after", ref: function (el) { return setAfterSlotWidth(el === null || el === void 0 ? void 0 : el.getBoundingClientRect().width); } }, { children: afterSlot })))] })), (readOnly || hasErrors || maxChars || message) && (_jsx(InputMessageGroup, { id: "".concat(id, "-meta"), message: readOnly && !message ? readOnlyText : message, errorMessage: hasErrors && (errorMessage || "Error"), count: maxChars ? (localInputValue === null || localInputValue === void 0 ? void 0 : localInputValue.toString().length) || 0 : undefined, maxCount: maxChars }))] }));
});
