var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
export var TabGroup = function (_a) {
    var defaultId = _a.defaultId, onChange = _a.onChange, activeId = _a.activeId, children = _a.children, className = _a.className, variant = _a.variant, name = _a.name, rest = __rest(_a, ["defaultId", "onChange", "activeId", "children", "className", "variant", "name"]);
    var _b = React.useState(activeId === undefined && defaultId ? defaultId : activeId), selected = _b[0], setSelected = _b[1];
    React.useEffect(function () {
        setSelected(activeId);
    }, [activeId]);
    var handleChange = function (e) {
        if (onChange)
            onChange(e);
        setSelected(e.target.id);
    };
    var getTabs = function () {
        return React.Children.map(children, function (tab) {
            var _a, _b, _c, _d;
            if (!tab)
                return null;
            return React.cloneElement(tab, {
                name: name,
                key: "".concat(name, "-").concat((_a = tab === null || tab === void 0 ? void 0 : tab.props) === null || _a === void 0 ? void 0 : _a.id),
                onChange: handleChange,
                active: ((_b = tab === null || tab === void 0 ? void 0 : tab.props) === null || _b === void 0 ? void 0 : _b.id) === selected ? true : undefined,
                className: classNames(variant && variant.includes("toggle") ? "eds-tab--toggle" : "eds-tab", variant && variant.includes("small") && "eds-tab--toggle-small", variant && variant.includes("medium") && "eds-tab--toggle-medium", {
                    "eds-tab--disabled": (_c = tab === null || tab === void 0 ? void 0 : tab.props) === null || _c === void 0 ? void 0 : _c.disabled,
                }, (_d = tab === null || tab === void 0 ? void 0 : tab.props) === null || _d === void 0 ? void 0 : _d.className),
            });
        });
    };
    return (_jsx("ul", __assign({ className: classNames(variant && variant.includes("toggle")
            ? "eds-tab-group--toggle"
            : "eds-tab-group", className), role: "tablist" }, rest, { children: getTabs() })));
};
