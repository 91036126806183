import { loop, liftState, getModel, getCmd, isLoop } from './loop';
import Cmd from './cmd';
import { install } from './install';
import c from './combine-reducers';
import m from './merge-child-reducers';
import r from './reduce-reducers'; //by exporting functions, these are able to be spied on
//should be unnecessary after https://github.com/rollup/rollup/issues/826

export function combineReducers() {
  return c.apply(void 0, arguments);
}
export function mergeChildReducers() {
  return m.apply(void 0, arguments);
}
export function reduceReducers() {
  return r.apply(void 0, arguments);
}
export { Cmd, install, loop, liftState, getModel, getCmd, isLoop };