var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { Text } from "../Text/Text";
export var InputMessageGroup = function (_a) {
    var count = _a.count, maxCount = _a.maxCount, message = _a.message, errorMessage = _a.errorMessage, className = _a.className, rest = __rest(_a, ["count", "maxCount", "message", "errorMessage", "className"]);
    if (!(errorMessage || message || count) && count !== 0)
        return null;
    return (_jsxs("div", __assign({ className: classNames("eds-input-message-group") }, rest, { children: [typeof message === "string" ? (_jsx(Text, __assign({ color: "text-secondary", className: "eds-input-message-group__message" }, { children: message }))) : (message), errorMessage && (_jsxs(Text, __assign({ as: "p", className: "eds-input-message-group__error-message" }, { children: [_jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: "Error" })), errorMessage] }))), !!(count === 0 || count) && (_jsxs("div", __assign({ className: "eds-input-message-group__counter" }, { children: [_jsx(Text, __assign({ as: "span", color: maxCount && count > maxCount ? "text-danger" : "text-secondary" }, { children: count })), maxCount && (_jsxs(_Fragment, { children: [_jsxs("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: ["of ", maxCount] })), _jsxs(Text, __assign({ as: "span", color: "text-secondary", "aria-hidden": true }, { children: ["/", maxCount] }))] }))] })))] })));
};
