var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
export var StatusIcon = function (_a) {
    var _b = _a.status, status = _b === void 0 ? "info" : _b, _c = _a.enableFocus, enableFocus = _c === void 0 ? false : _c, _d = _a.statusIconSize, statusIconSize = _d === void 0 ? "m" : _d, className = _a.className, rest = __rest(_a, ["status", "enableFocus", "statusIconSize", "className"]);
    // @todo - v2 switch the info and neutral icons and colors up
    var getIcon = function () {
        switch (status) {
            case "info":
                return (_jsx(Icon, { name: "Info", size: statusIconSize, backgroundShape: "circle", backgroundColor: "var(--eds-color-grey-1)", color: "var(--eds-color-white)", enableFocus: enableFocus }));
            case "success":
                return (_jsx(Icon, { name: "Check", "aria-label": "Success", size: statusIconSize, backgroundShape: "circle", backgroundColor: "var(--eds-color-background-success)", color: "var(--eds-color-white)", enableFocus: enableFocus }));
            case "warning":
                return (_jsx(Icon, { name: "Alert", "aria-label": "Warning", size: statusIconSize, backgroundShape: "circle", backgroundColor: "var(--eds-color-background-warning)", color: "var(--eds-color-black)", enableFocus: enableFocus }));
            case "error":
            case "danger":
                return (_jsx(Icon, { name: "Close", "aria-label": "Error", size: statusIconSize, backgroundShape: "circle", backgroundColor: "var(--eds-color-background-danger)", color: "var(--eds-color-white)", enableFocus: enableFocus }));
            case "neutral":
                return (_jsx(Icon, { name: "Info", "aria-label": "Neutral", size: statusIconSize, backgroundShape: "circle", backgroundColor: "var(--eds-color-text-link)", color: "var(--eds-color-white)", enableFocus: enableFocus }));
            default:
                return null;
        }
    };
    return (_jsx("div", __assign({ className: classNames("eds-status-icon", "eds-status-icon--".concat(statusIconSize), className) }, rest, { children: getIcon() })));
};
