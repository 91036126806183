var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { Label } from "../Label/Label";
import { InputMessageGroup } from "../InputMessageGroup/InputMessageGroup";
export var Select = React.forwardRef(function (_a, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
ref) {
    var noBorder = _a.noBorder, errorMessage = _a.errorMessage, id = _a.id, label = _a.label, subtitle = _a.subtitle, hasErrors = _a.hasErrors, _b = _a.hideLabel, hideLabel = _b === void 0 ? false : _b, message = _a.message, className = _a.className, isCreatable = _a.isCreatable, rest = __rest(_a, ["noBorder", "errorMessage", "id", "label", "subtitle", "hasErrors", "hideLabel", "message", "className", "isCreatable"]);
    var _c = useState("mouse"), lastInteraction = _c[0], setLastInteraction = _c[1];
    useEffect(function () {
        var keyboardListener = function () { return setLastInteraction("keyboard"); };
        var mouseListener = function () { return setLastInteraction("mouse"); };
        window.addEventListener("keydown", keyboardListener);
        window.addEventListener("mousedown", mouseListener);
        return function () {
            window.removeEventListener("keydown", keyboardListener);
            window.removeEventListener("mousedown", mouseListener);
        };
    }, []);
    var style = {
        control: function (base, state) { return (__assign(__assign({}, base), { boxShadow: state.isFocused && lastInteraction === "mouse"
                ? "none"
                : base.boxShadow })); },
    };
    return (_jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: id, font: "title-6", screenReaderOnly: hideLabel, hasErrors: hasErrors }, { children: label })), subtitle && (_jsx(Label, __assign({ htmlFor: id, font: "body-3", color: "text-secondary" }, { children: subtitle }))), _jsx("div", __assign({ className: classNames("eds-select eds-text-field", className) }, { children: isCreatable ? (_jsx(CreatableSelect, __assign({ ref: ref, className: classNames("eds-text-field__input eds-text-field--select", {
                        "eds-text-field__input--error": hasErrors,
                        "eds-text-field__input--no-border": noBorder === true,
                    }), id: "".concat(id, "-wrapper"), inputId: id, "aria-describedby": "".concat(id, "-meta"), classNamePrefix: "eds-select", 
                    // onCreateOption={onCreateOption}
                    styles: style }, rest))) : (_jsx(ReactSelect, __assign({ ref: ref, className: classNames("eds-text-field__input eds-text-field--select", {
                        "eds-text-field__input--error": hasErrors,
                        "eds-text-field__input--no-border": noBorder === true,
                    }), id: "".concat(id, "-wrapper"), inputId: id, "aria-describedby": "".concat(id, "-meta"), classNamePrefix: "eds-select", styles: style }, rest))) })), (hasErrors || message) && (_jsx(InputMessageGroup, { id: "".concat(id, "-meta"), message: message, errorMessage: hasErrors && (errorMessage || "Error") }))] }));
});
