function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

import { batchCmds } from './merge-child-reducers';
import { loop, isLoop, getModel, getCmd } from './loop';
export default (function () {
  for (var _len = arguments.length, reducers = new Array(_len), _key = 0; _key < _len; _key++) {
    reducers[_key] = arguments[_key];
  }

  return function (prevState, action) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
      args[_key2 - 2] = arguments[_key2];
    }

    var _reducers$reduce = reducers.reduce(function (prevResult, reducer) {
      var result = reducer.apply(void 0, [prevResult.newState, action].concat(args));

      if (isLoop(result)) {
        return {
          newState: getModel(result),
          cmds: [].concat(_toConsumableArray(prevResult.cmds), [getCmd(result)])
        };
      }

      return {
        newState: result,
        cmds: prevResult.cmds
      };
    }, {
      newState: prevState,
      cmds: []
    }),
        newState = _reducers$reduce.newState,
        cmds = _reducers$reduce.cmds;

    return loop(newState, batchCmds(cmds));
  };
});