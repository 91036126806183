import { useEffect } from "react";
export var useNoScrollBody = function (isOpen) {
    useEffect(function () {
        if (isOpen) {
            document.body.classList.add("eds-scrim--stop-scroll");
        }
        else {
            document.body.classList.remove("eds-scrim--stop-scroll");
        }
        // cleanup function if component gets unmounted
        return function () {
            document.body.classList.remove("eds-scrim--stop-scroll");
        };
    }, [isOpen]);
};
