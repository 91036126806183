var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import { deprecationPropWarning } from "../../utils/deprecation";
export var Box = function (props) {
    var backgroundColor = props.backgroundColor, children = props.children, className = props.className, height = props.height, m = props.m, margin = props.margin, marginBlockEnd = props.marginBlockEnd, marginBlockStart = props.marginBlockStart, marginInlineEnd = props.marginInlineEnd, marginInlineStart = props.marginInlineStart, maxHeight = props.maxHeight, maxWidth = props.maxWidth, mb = props.mb, minHeight = props.minHeight, minWidth = props.minWidth, ml = props.ml, mr = props.mr, mt = props.mt, padding = props.padding, paddingBlockEnd = props.paddingBlockEnd, paddingBlockStart = props.paddingBlockStart, paddingInlineEnd = props.paddingInlineEnd, paddingInlineStart = props.paddingInlineStart, style = props.style, width = props.width, rest = __rest(props, ["backgroundColor", "children", "className", "height", "m", "margin", "marginBlockEnd", "marginBlockStart", "marginInlineEnd", "marginInlineStart", "maxHeight", "maxWidth", "mb", "minHeight", "minWidth", "ml", "mr", "mt", "padding", "paddingBlockEnd", "paddingBlockStart", "paddingInlineEnd", "paddingInlineStart", "style", "width"]);
    var stringNumber = function (v) {
        return typeof v === "number" ? "".concat(v, "px") : v;
    };
    deprecationPropWarning("Box", props, [
        "margin",
        "marginBlockEnd",
        "marginBlockStart",
        "marginInlineEnd",
        "marginInlineStart",
        "padding",
        "paddingBlockEnd",
        "paddingBlockStart",
        "paddingInlineEnd",
        "paddingInlineStart",
        "backgroundColor",
    ]);
    return (_jsx("div", __assign({ className: classNames("eds-box", className), style: __assign({ height: stringNumber(height), width: stringNumber(width), maxHeight: stringNumber(maxHeight), maxWidth: stringNumber(maxWidth), minHeight: stringNumber(minHeight), minWidth: stringNumber(minWidth), margin: stringNumber(m !== null && m !== void 0 ? m : margin), marginTop: stringNumber(mt !== null && mt !== void 0 ? mt : marginBlockStart), marginBottom: stringNumber(mb !== null && mb !== void 0 ? mb : marginBlockEnd), marginLeft: stringNumber(ml !== null && ml !== void 0 ? ml : marginInlineStart), marginRight: stringNumber(mr !== null && mr !== void 0 ? mr : marginInlineEnd), padding: stringNumber(padding), paddingBlockStart: stringNumber(paddingBlockStart), paddingBlockEnd: stringNumber(paddingBlockEnd), paddingInlineStart: stringNumber(paddingInlineStart), paddingInlineEnd: stringNumber(paddingInlineEnd), backgroundColor: stringNumber(backgroundColor) }, style) }, rest, { children: children })));
};
