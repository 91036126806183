var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
var DEFAULT_LINK_AS = "a";
export var Link = function (_a) {
    var _b;
    var as = _a.as, children = _a.children, className = _a.className, _c = _a.variant, variant = _c === void 0 ? "primary" : _c, font = _a.font, _d = _a.disabled, disabled = _d === void 0 ? false : _d, rest = __rest(_a, ["as", "children", "className", "variant", "font", "disabled"]);
    var ariaDisabled = disabled ? { "aria-disabled": true } : {};
    var Tag = as || DEFAULT_LINK_AS;
    return (_jsx(Tag, __assign({ className: classNames("eds-link", (_b = {},
            _b["eds-link--".concat(variant)] = variant !== "primary",
            _b["eds-link--button"] = as === "button",
            _b["eds-type--".concat(font)] = font,
            _b["eds-link--disable"] = disabled,
            _b), className) }, (disabled && { disabled: disabled }), ariaDisabled, rest, { children: children })));
};
