var _Object$freeze3;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

import { throwInvariant, flatten, isPromiseLike } from './utils';
var isCmdSymbol = Symbol('isCmd');
var dispatchSymbol = Symbol('dispatch');
var getStateSymbol = Symbol('getState');
var cmdTypes = {
  RUN: 'RUN',
  ACTION: 'ACTION',
  LIST: 'LIST',
  MAP: 'MAP',
  NONE: 'NONE'
};
export function isCmd(object) {
  return object ? !!object[isCmdSymbol] : false;
}

function getMappedCmdArgs() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var dispatch = arguments.length > 1 ? arguments[1] : undefined;
  var getState = arguments.length > 2 ? arguments[2] : undefined;
  return args.map(function (arg) {
    if (arg === dispatchSymbol) {
      return dispatch;
    } else if (arg === getStateSymbol) {
      return getState;
    } else {
      return arg;
    }
  });
}

function handleRunCmd(cmd, dispatch, getState) {
  var loopConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var onSuccess = cmd.successActionCreator || function () {};

  var onFail;

  if (cmd.failActionCreator) {
    onFail = function onFail(error) {
      if (!loopConfig.DONT_LOG_ERRORS_ON_HANDLED_FAILURES) {
        console.error(error);
      }

      return cmd.failActionCreator(error);
    };
  } else {
    onFail = console.error;
  }

  try {
    var result = cmd.func.apply(cmd, _toConsumableArray(getMappedCmdArgs(cmd.args, dispatch, getState)));

    if (isPromiseLike(result) && !cmd.forceSync) {
      return result.then(onSuccess, onFail).then(function (action) {
        return action ? [action] : [];
      });
    }

    var resultAction = onSuccess(result);
    return resultAction ? Promise.resolve([resultAction]) : null;
  } catch (err) {
    if (!cmd.failActionCreator) {
      console.error(err);
      throw err; //don't swallow errors if they are not handling them
    }

    var _resultAction = onFail(err);

    return _resultAction ? Promise.resolve([_resultAction]) : null;
  }
}

function handleParallelList(_ref, dispatch, getState) {
  var cmds = _ref.cmds,
      _ref$batch = _ref.batch,
      batch = _ref$batch === void 0 ? false : _ref$batch;
  var loopConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var promises = cmds.map(function (nestedCmd) {
    var possiblePromise = executeCmd(nestedCmd, dispatch, getState, loopConfig);

    if (!possiblePromise || batch) {
      return possiblePromise;
    }

    return possiblePromise.then(function (result) {
      return Promise.all(result.map(function (a) {
        return dispatch(a);
      }));
    });
  }).filter(function (x) {
    return x;
  });

  if (promises.length === 0) {
    return null;
  }

  return Promise.all(promises).then(flatten).then(function (actions) {
    return batch ? actions : [];
  });
}

function handleSequenceList(_ref2, dispatch, getState) {
  var cmds = _ref2.cmds,
      _ref2$batch = _ref2.batch,
      batch = _ref2$batch === void 0 ? false : _ref2$batch;
  var loopConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var firstCmd = cmds.length ? cmds[0] : null;

  if (!firstCmd) {
    return null;
  }

  var result = new Promise(function (resolve) {
    var firstPromise = executeCmd(firstCmd, dispatch, getState, loopConfig);
    firstPromise = firstPromise || Promise.resolve([]);
    firstPromise.then(function (result) {
      var executePromise;

      if (!batch) {
        executePromise = Promise.all(result.map(function (a) {
          return dispatch(a);
        }));
      } else {
        executePromise = Promise.resolve();
      }

      executePromise.then(function () {
        var remainingSequence = list(cmds.slice(1), {
          batch: batch,
          sequence: true
        });
        var remainingPromise = executeCmd(remainingSequence, dispatch, getState, loopConfig);

        if (remainingPromise) {
          remainingPromise.then(function (innerResult) {
            resolve(result.concat(innerResult));
          });
        } else {
          resolve(result);
        }
      });
    });
  }).then(flatten);
  return batch ? result : result.then(function () {
    return [];
  });
}

export function executeCmd(cmd, dispatch, getState) {
  var loopConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  switch (cmd.type) {
    case cmdTypes.RUN:
      return handleRunCmd(cmd, dispatch, getState, loopConfig);

    case cmdTypes.ACTION:
      return Promise.resolve([cmd.actionToDispatch]);

    case cmdTypes.LIST:
      return cmd.sequence ? handleSequenceList(cmd, dispatch, getState, loopConfig) : handleParallelList(cmd, dispatch, getState, loopConfig);

    case cmdTypes.MAP:
      {
        var possiblePromise = executeCmd(cmd.nestedCmd, dispatch, getState, loopConfig);

        if (!possiblePromise) {
          return null;
        }

        return possiblePromise.then(function (actions) {
          return actions.map(function (action) {
            return cmd.tagger.apply(cmd, _toConsumableArray(cmd.args).concat([action]));
          });
        });
      }

    case cmdTypes.NONE:
      return null;

    default:
      throw new Error("Invalid Cmd type ".concat(cmd.type));
  }
}

function simulateRun(_ref3) {
  var result = _ref3.result,
      success = _ref3.success;

  if (success && this.successActionCreator) {
    return this.successActionCreator(result);
  } else if (!success && this.failActionCreator) {
    return this.failActionCreator(result);
  }

  return null;
}

function run(func) {
  var _objectSpread2;

  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (process.env.NODE_ENV !== 'production') {
    if (!options.testInvariants) {
      throwInvariant(typeof func === 'function', 'Cmd.run: first argument to Cmd.run must be a function');
      throwInvariant(_typeof(options) === 'object', 'Cmd.run: second argument to Cmd.run must be an options object');
      throwInvariant(!options.successActionCreator || typeof options.successActionCreator === 'function', 'Cmd.run: successActionCreator option must be a function if specified');
      throwInvariant(!options.failActionCreator || typeof options.failActionCreator === 'function', 'Cmd.run: failActionCreator option must be a function if specified');
      throwInvariant(!options.args || options.args.constructor === Array, 'Cmd.run: args option must be an array if specified');
    }
  } else if (options.testInvariants) {
    throw Error("Redux Loop: Detected usage of Cmd.run's testInvariants option in production code. This should only be used in tests.");
  }

  var testInvariants = options.testInvariants,
      rest = _objectWithoutProperties(options, ["testInvariants"]);

  return Object.freeze(_objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, isCmdSymbol, true), _defineProperty(_objectSpread2, "type", cmdTypes.RUN), _defineProperty(_objectSpread2, "func", func), _defineProperty(_objectSpread2, "simulate", simulateRun), _objectSpread2), rest));
}

function simulateAction() {
  return this.actionToDispatch;
}

function action(actionToDispatch) {
  var _Object$freeze;

  if (process.env.NODE_ENV !== 'production') {
    throwInvariant(_typeof(actionToDispatch) === 'object' && actionToDispatch !== null && typeof actionToDispatch.type !== 'undefined', 'Cmd.action: first argument and only argument to Cmd.action must be an action');
  }

  return Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, isCmdSymbol, true), _defineProperty(_Object$freeze, "type", cmdTypes.ACTION), _defineProperty(_Object$freeze, "actionToDispatch", actionToDispatch), _defineProperty(_Object$freeze, "simulate", simulateAction), _Object$freeze));
}

function simulateList(simulations) {
  return flatten(this.cmds.map(function (cmd, i) {
    return cmd.simulate(simulations[i]);
  }).filter(function (a) {
    return a;
  }));
}

function list(cmds) {
  var _objectSpread3;

  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (process.env.NODE_ENV !== 'production') {
    if (!options.testInvariants) {
      throwInvariant(Array.isArray(cmds) && cmds.every(isCmd), 'Cmd.list: first argument to Cmd.list must be an array of other Cmds');
      throwInvariant(_typeof(options) === 'object', 'Cmd.list: second argument to Cmd.list must be an options object');
    }
  } else if (options.testInvariants) {
    throw Error("Redux Loop: Detected usage of Cmd.list's testInvariants option in production code. This should only be used in tests.");
  }

  var testInvariants = options.testInvariants,
      rest = _objectWithoutProperties(options, ["testInvariants"]);

  return Object.freeze(_objectSpread((_objectSpread3 = {}, _defineProperty(_objectSpread3, isCmdSymbol, true), _defineProperty(_objectSpread3, "type", cmdTypes.LIST), _defineProperty(_objectSpread3, "cmds", cmds), _defineProperty(_objectSpread3, "simulate", simulateList), _objectSpread3), rest));
}

function simulateMap(simulation) {
  var _this = this;

  var result = this.nestedCmd.simulate(simulation);

  if (Array.isArray(result)) {
    return result.map(function (action) {
      return _this.tagger.apply(_this, _toConsumableArray(_this.args).concat([action]));
    });
  } else if (result) {
    return this.tagger.apply(this, _toConsumableArray(this.args).concat([result]));
  } else {
    return null;
  }
}

function map(nestedCmd, tagger) {
  var _Object$freeze2;

  if (process.env.NODE_ENV !== 'production') {
    throwInvariant(isCmd(nestedCmd), 'Cmd.map: first argument to Cmd.map must be another Cmd');
    throwInvariant(typeof tagger === 'function', 'Cmd.map: second argument to Cmd.map must be a function that returns an action');
  }

  for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    args[_key - 2] = arguments[_key];
  }

  return Object.freeze((_Object$freeze2 = {}, _defineProperty(_Object$freeze2, isCmdSymbol, true), _defineProperty(_Object$freeze2, "type", cmdTypes.MAP), _defineProperty(_Object$freeze2, "tagger", tagger), _defineProperty(_Object$freeze2, "nestedCmd", nestedCmd), _defineProperty(_Object$freeze2, "args", args), _defineProperty(_Object$freeze2, "simulate", simulateMap), _Object$freeze2));
}

var none = Object.freeze((_Object$freeze3 = {}, _defineProperty(_Object$freeze3, isCmdSymbol, true), _defineProperty(_Object$freeze3, "type", cmdTypes.NONE), _defineProperty(_Object$freeze3, "simulate", function simulate() {
  return null;
}), _Object$freeze3));
export default {
  run: run,
  action: action,
  list: list,
  map: map,
  none: none,
  dispatch: dispatchSymbol,
  getState: getStateSymbol
};