var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";
import useOnClickOutside from "../../utils/useOnClickOutsideHook";
export var Menu = function (_a) {
    var headerSlot = _a.headerSlot, bodySlot = _a.bodySlot, footerSlot = _a.footerSlot, children = _a.children, className = _a.className, isOpen = _a.isOpen, isDark = _a.isDark, onClickOutside = _a.onClickOutside, modifiers = _a.modifiers, _b = _a.placement, placement = _b === void 0 ? "bottom" : _b, rest = __rest(_a, ["headerSlot", "bodySlot", "footerSlot", "children", "className", "isOpen", "isDark", "onClickOutside", "modifiers", "placement"]);
    var _c = useState(null), popperElement = _c[0], setPopperElement = _c[1];
    var _d = useState(null), referenceElement = _d[0], setReferenceElement = _d[1];
    var _e = useState(null), arrowElement = _e[0], setArrowElement = _e[1];
    var _f = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: __spreadArray([
            {
                name: "offset",
                options: {
                    offset: [0, 12],
                },
            },
            {
                name: "flip",
                enabled: false,
            },
            { name: "arrow", options: { element: arrowElement } }
        ], (modifiers !== null && modifiers !== void 0 ? modifiers : []), true),
    }), styles = _f.styles, attributes = _f.attributes;
    if (onClickOutside) {
        useOnClickOutside({
            ref: popperElement,
            ignoreRefs: [referenceElement],
            handler: onClickOutside,
            isActive: isOpen,
            dep: [referenceElement],
        });
    }
    var menuClasses = classNames("eds-menu", isDark && "eds--dark", className);
    return (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: "eds-menu__anchor", ref: setReferenceElement }, { children: children })), _jsx("div", __assign({ ref: setPopperElement, className: menuClasses, style: styles.popper }, attributes.popper, rest, { children: isOpen && (_jsxs(_Fragment, { children: [headerSlot && _jsx("div", __assign({ className: "eds-menu__header" }, { children: headerSlot })), bodySlot && _jsx("ul", __assign({ className: "eds-menu__body" }, { children: bodySlot })), footerSlot && _jsx("div", __assign({ className: "eds-menu__footer" }, { children: footerSlot })), _jsx("div", { className: "eds-menu__arrow", ref: setArrowElement, style: styles.arrow })] })) }))] }));
};
