function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { liftState } from './loop';
import { executeCmd } from './cmd';
import { loopPromiseCaughtError } from './errors';
var defaultLoopConfig = {
  DONT_LOG_ERRORS_ON_HANDLED_FAILURES: false,
  ENABLE_THUNK_MIGRATION: false
};
export function install() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var loopConfig = Object.assign({}, defaultLoopConfig, config);
  return function (next) {
    return function (reducer, initialState, enhancer) {
      var _liftState = liftState(initialState),
          _liftState2 = _slicedToArray(_liftState, 2),
          initialModel = _liftState2[0],
          initialCmd = _liftState2[1];

      var cmdsQueue = [];

      var liftReducer = function liftReducer(reducer) {
        return function (state, action) {
          var result = reducer(state, action);

          var _liftState3 = liftState(result),
              _liftState4 = _slicedToArray(_liftState3, 2),
              model = _liftState4[0],
              cmd = _liftState4[1];

          cmdsQueue.push({
            originalAction: action,
            cmd: cmd
          });
          return model;
        };
      };

      var store = next(liftReducer(reducer), initialModel, enhancer);

      function runCmds(queue) {
        var promises = queue.map(runCmd).filter(function (x) {
          return x;
        });

        if (promises.length === 0) {
          return Promise.resolve();
        } else if (promises.length === 1) {
          return promises[0];
        } else {
          return Promise.all(promises).then(function () {});
        }
      }

      function runCmd(_ref) {
        var originalAction = _ref.originalAction,
            cmd = _ref.cmd;
        var cmdPromise = executeCmd(cmd, dispatch, store.getState, loopConfig);

        if (!cmdPromise) {
          return null;
        }

        return cmdPromise.then(function (actions) {
          if (!actions.length) {
            return;
          }

          return Promise.all(actions.map(dispatch));
        })["catch"](function (error) {
          console.error(loopPromiseCaughtError(originalAction.type, error));
          throw error;
        });
      }

      function dispatch(action) {
        if (loopConfig.ENABLE_THUNK_MIGRATION && typeof action === 'function') {
          return action(dispatch, store.getState);
        }

        var result = store.dispatch(action);
        var cmdsToRun = cmdsQueue;
        cmdsQueue = [];
        return runCmds(cmdsToRun).then(function () {
          return result;
        });
      }

      function replaceReducer(reducer) {
        return store.replaceReducer(liftReducer(reducer));
      }

      runCmd({
        originalAction: {
          type: '@@ReduxLoop/INIT'
        },
        cmd: initialCmd
      });
      return _objectSpread({}, store, {
        dispatch: dispatch,
        replaceReducer: replaceReducer
      });
    };
  };
}