var filterMeta = {
    name: "Filters",
    figma: "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=6542%3A321211",
    tags: ["Chips", "Filters"],
    designStatuses: ["In Development"],
    devStatuses: ["Backlog"],
};
var profileMenu = {
    name: "Profile Menu",
    figma: "https://www.figma.com/file/G7AMx6Xc2WtiNnsmCsAxxi/Enterprise-DS-Library?node-id=404%3A12174",
    tags: ["Inputs", "Profile Menu"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var dateRangeMeta = {
    name: "Date Range Picker",
    // figma: "",
    tags: ["Inputs", "Date Range Picker"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var richTextEditorMeta = {
    name: "Rich Text Editor",
    // figma: "",
    tags: ["Input", "RTE"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var stackMeta = {
    name: "Stack",
    tags: ["stack"],
    designStatuses: ["Discovery"],
    devStatuses: ["Plan"],
};
var paginationMeta = {
    name: "Pagination",
    tags: ["pagination"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var accordionMeta = {
    name: "Accordion",
    tags: ["accordion"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var searchInputMeta = {
    name: "Search",
    tags: ["search input"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var sliderMeta = {
    name: "Slider",
    tags: ["slider input", "forms"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var listMeta = {
    name: "List",
    tags: [],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var ratingMeta = {
    name: "Rating",
    tags: [],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
var progressIndicatorMeta = {
    name: "Progress Indicator",
    tags: ["forms"],
    designStatuses: ["Backlog"],
    devStatuses: ["Backlog"],
};
export var backlog = [
    accordionMeta,
    dateRangeMeta,
    filterMeta,
    listMeta,
    profileMenu,
    paginationMeta,
    progressIndicatorMeta,
    ratingMeta,
    richTextEditorMeta,
    searchInputMeta,
    sliderMeta,
    stackMeta,
    {
        name: "Data Visualization: Area Charts",
        tags: ["Visualizations", "Data Visualization"],
        designStatuses: ["Plan"],
        devStatuses: ["Plan"],
    },
    {
        name: "Data Visualization: Scatter Charts",
        tags: ["Visualizations", "Data Visualization"],
        designStatuses: ["Plan"],
        devStatuses: ["Plan"],
    },
];
