var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import classNames from "classnames";
import { StatusIcon } from "../StatusIcon/StatusIcon";
import { Icon } from "../Icon/Icon";
import { Button } from "../Button/Button";
export var Snack = function (_a) {
    var id = _a.id, className = _a.className, _b = _a.status, status = _b === void 0 ? "info" : _b, onDismiss = _a.onDismiss, hideDismiss = _a.hideDismiss, customIcon = _a.customIcon, children = _a.children, rest = __rest(_a, ["id", "className", "status", "onDismiss", "hideDismiss", "customIcon", "children"]);
    var _c = rest.autoDismissDuration, autoDismissDuration = _c === void 0 ? hideDismiss ? 5000 : undefined : _c, htmlAttributes = __rest(rest, ["autoDismissDuration"]);
    useEffect(function () {
        if (autoDismissDuration) {
            setTimeout(function () {
                onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(id);
            }, autoDismissDuration);
        }
    }, [autoDismissDuration, onDismiss]);
    return (_jsxs("div", __assign({ className: classNames("eds-snack", className), "aria-live": "polite", role: "status", id: id }, htmlAttributes, { children: [customIcon || _jsx(StatusIcon, { status: status }), _jsx("div", __assign({ className: "eds-snack__body" }, { children: children })), !hideDismiss && (_jsx(Button, __assign({ variant: "ghost", onClick: function () {
                    onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(id);
                }, "aria-label": "Close" }, { children: _jsx(Icon, { name: "Close" }) })))] })));
};
