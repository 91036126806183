var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useRef } from "react";
import FocusTrap from "focus-trap-react";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { Button } from "../Button/Button";
import useOnClickOutside from "../../utils/useOnClickOutsideHook";
import { useNoScrollBody } from "../../utils/useBodyNoScroll";
export var Modal = function (_a) {
    var isOpen = _a.isOpen, headerSlot = _a.headerSlot, children = _a.children, footerSlot = _a.footerSlot, onDismiss = _a.onDismiss, disableFocusTrap = _a.disableFocusTrap, demoMode = _a.demoMode, className = _a.className, displayCheckOverride = _a.displayCheckOverride, hideFade = _a.hideFade, rest = __rest(_a, ["isOpen", "headerSlot", "children", "footerSlot", "onDismiss", "disableFocusTrap", "demoMode", "className", "displayCheckOverride", "hideFade"]);
    var modalRef = useRef(null);
    var contentContainerRef = React.useRef(null);
    var _b = React.useState({
        top: false,
        mid: false,
        bottom: false,
    }), scrollPosition = _b[0], setScrollPosition = _b[1];
    // Check for overflow on initial render
    React.useEffect(function () {
        if (contentContainerRef.current) {
            var _a = contentContainerRef.current, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            var hasOverflow = scrollHeight > clientHeight;
            setScrollPosition({
                top: hasOverflow,
                mid: false,
                bottom: false,
            });
        }
    }, [isOpen]);
    // Update scrollPosition. Only active when scroll exists
    var onScroll = function () {
        if (contentContainerRef.current) {
            var _a = contentContainerRef.current, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            var isBottom = Math.round(scrollTop) + Math.round(clientHeight) ===
                Math.round(scrollHeight);
            var isTop = scrollTop === 0;
            var isMid = !isBottom && !isTop;
            setScrollPosition({
                top: isTop,
                mid: isMid,
                bottom: isBottom,
            });
        }
    };
    var handleClickOutside = useCallback(function (event) {
        var target = event.target;
        if (target.classList.contains("eds-scrim")) {
            onDismiss();
        }
    }, [onDismiss]);
    useOnClickOutside({
        ref: modalRef,
        handler: handleClickOutside,
        isActive: isOpen,
        dep: [modalRef],
    });
    // stop body scroll on isOpen
    useNoScrollBody(isOpen && !demoMode);
    return (_jsx(FocusTrap, __assign({ active: disableFocusTrap ? false : isOpen, focusTrapOptions: {
            escapeDeactivates: false,
            tabbableOptions: {
                displayCheck: displayCheckOverride ? "none" : "full",
            },
        } }, { children: _jsx("aside", __assign({ className: classNames("eds-scrim", { "eds-scrim--hidden": !isOpen }, className), onKeyDown: function (e) { return e.key === "Escape" && onDismiss(); } }, rest, { children: _jsxs("div", __assign({ ref: modalRef, className: "eds-modal" }, { children: [_jsxs("div", __assign({ className: "eds-modal__header" }, { children: [typeof headerSlot === "string" ? (_jsx("h2", { children: headerSlot })) : (headerSlot), _jsxs(Button, __assign({ onClick: function () { return onDismiss(); }, variant: "ghost" }, { children: [_jsx(Icon, { name: "Close", className: "eds-modal__close" }), _jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: "Close Modal" }))] }))] })), _jsx("div", __assign({ className: "eds-modal__content" }, { children: _jsxs("div", __assign({ onScroll: onScroll, ref: contentContainerRef }, { children: [!hideFade && (_jsx("span", { className: scrollPosition.bottom || scrollPosition.mid
                                        ? "eds-modal__top-fade eds-fade-in"
                                        : "eds-modal--hide" })), children, !hideFade && (_jsx("span", { className: scrollPosition.top || scrollPosition.mid
                                        ? "eds-modal__bottom-fade eds-fade-in"
                                        : "eds-modal--hide" }))] })) })), footerSlot && (_jsxs(_Fragment, { children: [_jsx("div", { className: "eds-modal__divider" }), _jsx("div", __assign({ className: "eds-modal__footer" }, { children: footerSlot }))] }))] })) })) })));
};
