import { Input } from '@nike/frame-component-library';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { bulkCreateStores } from '../../../utils/service-calls/sls';
import { REQUIRED_FIELD } from '../../../utils/validation/input-validation';
import bulkStoreCreateValidator from '../../../utils/validation/sls-bulk-create';
import { ButtonBlack, ButtonSubmit, ButtonWhite } from '../../reusable';

const maxLength = 300;
const maxParseErrors = 100;
const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
// List of email addresses that will receive notifications from the bulk create service with results of a bulk create.
const regularRecipients = [
  'Writuraj.Sarma@nike.com',
  'Erik.Jacobsen@nike.com',
  'Venkatesh.Krishnan@nike.com',
  'Michael.Mackrory@nike.com',
];

const BulkCreateStores = ({ ccmConfig, userEmail, userToken }) => {
  const [formErrors, setFormErrors] = useState('');
  const [allStores, setAllStores] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  // This is used by the 'Clear' function to get a new key for the component such that it's a brand new input when we change its
  // key, thus clearing the files that have been uploaded
  const [fileComponentKey, setFileComponentKey] = useState(Date.now());

  const notificationEmails = [userEmail, ...regularRecipients];

  const exportExampleCsv = () => {
    const csvBodyBuilder = (line) => `data:text/csv;charset=utf-8,${line.map((text) => `"${text}"`).join()}`;
    const csv = document.createElement('a');
    const exampleLine = [
      '{store number}',
      '{store name}',
      '{description}',
      '{facility type}',
      '{company}',
      '{business concept}',
      '{brand}',
      '{email}',
      '{primary phone number}',
      '{address1}',
      '{address2}',
      '{address3}',
      '{city}',
      '{state}',
      '{postalCode}',
      '{country, 3 character code}',
      '{latitude}',
      '{longitude}',
      '{timezone}',
      '{region}',
      '{locale}',
      '{opening date, yyyy-MM-dd}',
      '{store status}',
      '{partner name}',
      ...days.flatMap((day) => [
        `{${day} closed all day? YES or NO}`,
        `{${day} Local Open Time, HH:MM (24 Hour), if Closed all day==CLOSED`,
        `{${day} Local Closed Time, HH:MM (24 Hour), Required if Closed all day==CLOSED}`,
      ]),
    ];
    csv.href = csvBodyBuilder(exampleLine);
    csv.target = '_blank';
    csv.download = 'bulk-create-template.csv';
    csv.click();
  };

  const resetOnBadParse = (errorMessage) => {
    setAllStores({});
    setFormErrors(errorMessage);
  };

  const handleStep = (results, parser, errors, validatedStores, index) => {
    const { data } = results;
    const keys = [
      'storeNumber',
      'storeName',
      'description',
      'facilityType',
      'company',
      'businessConcept',
      'brand',
      'email',
      'phone',
      'address1',
      'address2',
      'address3',
      'city',
      'state',
      'postalCode',
      'country',
      'latitude',
      'longitude',
      'timezone',
      'region',
      'locale',
      'openingDate',
      'storeStatus',
      'partnerName',
      ...days.flatMap((day) => [
        `${day}ClosedAllDay`,
        `${day}LocalOpenTime`,
        `${day}LocalCloseTime`,
      ]),
    ];
    const extractedData = keys.reduce((acc, cur, i) => ({ ...acc, [cur]: data[i] }), {});
    const validatorResult = bulkStoreCreateValidator(extractedData, index, validatedStores, ccmConfig);

    if (validatorResult.errors.length > 0) {
      errors.push(...validatorResult.errors);
    } else {
      try {
        const store = {
          address: {
            address1: extractedData.address1,
            address2: extractedData.address2,
            address3: extractedData.address3,
            city: extractedData.city,
            country: extractedData.country,
            postalCode: extractedData.postalCode,
            state: extractedData.state,
          },
          brand: extractedData.brand,
          businessConcept: extractedData.businessConcept,
          company: extractedData.company,
          coordinates: {
            latitude: Number(extractedData.latitude),
            longitude: Number(extractedData.longitude),
          },
          description: extractedData.description,
          email: extractedData.email,
          facilityType: extractedData.facilityType,
          locale: extractedData.locale,
          name: extractedData.storeName,
          operationalDetails: {
            hoursOfOperation: {
              regularHours: days.reduce((acc, day) => {
                const hours = extractedData[`${day}ClosedAllDay`] === 'YES'
                  ? []
                  : [{
                    localCloseTime: extractedData[`${day}LocalCloseTime`],
                    localOpenTime: extractedData[`${day}LocalOpenTime`],
                  }];
                return { ...acc, [day]: hours };
              }, {}),
            },
            openingDate: extractedData.openingDate,
          },
          partnerName: extractedData.partnerName,
          phone: extractedData.phone,
          region: extractedData.region,
          storeNumber: extractedData.storeNumber,
          storeStatus: extractedData.storeStatus.toUpperCase(),
          timezone: extractedData.timezone,
        };

        validatedStores.push(store);
      } catch (err) {
        resetOnBadParse(`Something went wrong while attempting to parse your file: ${err.message}`);
        parser.abort();
      }
    }
  };

  const onParseComplete = (file, errors, validatedStores) => {
    if (errors.length > maxParseErrors) {
      setFormErrors('File contains too many errors');
    } else if (errors.length > 0) {
      let errorMessages = [];
      errorMessages = errors.map((error) => error.message);
      setFormErrors(errorMessages.join(', '));
    } else {
      allStores.stores = [...validatedStores];
      setAllStores({ ...allStores });
    }
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    const errors = [];
    const validatedStores = [];
    let index = 0;
    Papa.parse(file, {
      beforeFirstChunk: (parsedFile) => {
        const csvLength = parsedFile.split('\n').length;
        setFormErrors(csvLength > maxLength ? `File contains ${csvLength} rows, which is more than the maximum: ${maxLength}` : '');
      },
      comments: true,
      complete: (newFile) => onParseComplete(newFile, errors, validatedStores),
      error: (e) => resetOnBadParse(e),
      header: false,
      step: (partialResults, parser) => !formErrors && handleStep(partialResults, parser, errors, validatedStores, ++index),
    });
  };

  const clear = () => {
    setFormErrors('');
    setAllStores({});
    setSubmitSuccess('');
    setSubmitError('');
    setFileComponentKey(Date.now());
  };

  const submit = () => {
    setIsSubmitting(true);
    setSubmitSuccess('');
    setSubmitError('');
    // eslint-disable-next-line promise/catch-or-return
    bulkCreateStores(userToken, {
      contactEmails: notificationEmails,
      objects: allStores.stores,
    })
      .then(() => setSubmitSuccess(`Store creation process successfully started. Results emails will be sent to ${notificationEmails.join(', ')}`))
      .catch((err) => setSubmitError(`There was a problem starting the store creation process: ${err.message}`))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <section className="ncss-row pb6-sm va-sm-t">
      <article className="ncss-col-lg-3 ncss-col-sm-12">
        <h1 className="text-color-accent body-1 mt2-sm mb-2-sm">Bulk Create Stores</h1>
        <ButtonBlack
          className="mt3-sm"
          label="Download Template CSV"
          onClick={exportExampleCsv}
        />
      </article>
      <article className="ncss-col-lg-3 ncss-col-sm-9 va-sm-t mt3-sm">
        <Input
          key={fileComponentKey}
          accept=".csv"
          datatype="text"
          errorMessage={((formErrors) || ((Object.keys(allStores).length === 0 || allStores.stores?.length === 0) ? REQUIRED_FIELD : ''))}
          id="fileEditStore"
          label="File Upload"
          type="file"
          onChange={handleFile}
        />
      </article>
      <article className="ncss-col-lg-2 ncss-col-sm-3 va-sm-t">
        <ButtonWhite
          isDisabled={isSubmitting}
          label="Clear"
          onClick={clear}
        />
      </article>
      <article className="ncss-col-lg-4 ncss-col-sm-12 va-sm-t ta-sm-c">
        <ButtonSubmit
          className="va-sm-t"
          isDisabled={Object.keys(allStores).length === 0 || allStores.stores?.length === 0 || isSubmitting || submitSuccess !== '' || submitError !== ''}
          label="Bulk Create Stores"
          onClick={submit}
        />
      </article>
      <article className="ncss-col-sm-12 ta-sm-c">
        {submitSuccess && <p className="text-color-success">{submitSuccess}</p>}
        {submitError && <p className="text-color-error">{submitError}</p>}
      </article>
    </section>
  );
};

BulkCreateStores.propTypes = {
  ccmConfig: PropTypes.shape().isRequired,
  userEmail: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default BulkCreateStores;
