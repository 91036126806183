/* eslint-disable no-useless-escape */
import { copy } from '@nike/rcf-fp';
import lodash from 'lodash';

import { requestWrapper } from './reusable';

const config = require('../../config')();

/* Okta Auth Token */

export const getOktaToken = (token) => requestWrapper(
  token,
  config.slsAuthTokenResource,
  'get',
);

/* STORES */

const getStoreByIdImpl = (token, storeId) => requestWrapper(
  token,
  config.slsStoreResource + storeId,
  'get',
);

const compareHours = ({ localOpenTime: lTime = '' }, { localOpenTime: rTime = '' }) => lTime.localeCompare(rTime);

const withSortedHours = ({ hours = [], ...restOfSpecialHour }) => ({
  ...restOfSpecialHour,
  hours: hours.sort(compareHours),
});

const compareFirstOpenTimes = (lHours, rHours) => {
  const getFirstOpenTime = ([firstHour = {}]) => firstHour.localOpenTime ?? '';
  const lOpenTime = getFirstOpenTime(lHours);
  const rOpenTime = getFirstOpenTime(rHours);
  return lOpenTime.localeCompare(rOpenTime);
};

const compareSpecialHours = ({ date: lDate = '', hours: lHours = [] }, { date: rDate = '', hours: rHours = [] }) => {
  const dateComparison = lDate.localeCompare(rDate);
  return dateComparison !== 0
    ? dateComparison
    : compareFirstOpenTimes(lHours, rHours);
};

const withSortedSpecialHours = (store) => {
  const specialHours = store?.operationalDetails?.hoursOfOperation?.specialHours ?? [];
  const sortedSpecialHours = specialHours
    .map(withSortedHours)
    .sort(compareSpecialHours);
  return copy(store, [['operationalDetails', 'hoursOfOperation', 'specialHours'], sortedSpecialHours]);
};

export const getStoreById = (token, storeId) => getStoreByIdImpl(token, storeId)
  .then(({ body: store, ...restOfResponse }) => ({ ...restOfResponse, body: withSortedSpecialHours(store) }));

const getSearchParamForRegion = (region) => (region ? { search: `(region==${region})` } : {});

const getStoresPage = async (token, anchor, pageSize, fields, region) => requestWrapper(
  token,
  config.slsStoresResource,
  'get',
  {
    anchor, count: pageSize, fields, ...getSearchParamForRegion(region),
  },
);

const getStoreCount = async (token, region) => requestWrapper(
  token,
  config.slsStoresResource,
  'get',
  { count: 1, fields: 'id', ...getSearchParamForRegion(region) },
).then((response) => response.body.pages.totalResources);

export const getStores = (token, fields, region) => new Promise((resolve, reject) => {
  (async () => {
    const PAGE_SIZE = 1000;
    const storeCount = await getStoreCount(token, region);
    const pageCount = storeCount % PAGE_SIZE === 0
      ? Math.trunc(storeCount / PAGE_SIZE)
      : Math.trunc(storeCount / PAGE_SIZE) + 1;
    const pages = await Promise.all(lodash.range(0, pageCount)
      .map((pageNumber) => getStoresPage(token, (pageNumber * PAGE_SIZE), PAGE_SIZE, fields, region)));
    const stores = pages.map((page) => page.body.objects).flat();
    return resolve(stores);
  })().catch(() => reject(new Error('Could not fetch all Stores.')));
});

export const getConfig = async (accessToken) => {
  try {
    const response = await requestWrapper(accessToken, '/ccm/config', 'get');
    const data = response.body;
    return data.reduce((configObject, conf) => {
      if (conf.key.startsWith('sls.')) {
        configObject[conf.key] = conf.value
          .replace(/^\[|\]$/g, '') // Remove leading and trailing square brackets
          .split(',') // Split based on comma
          .map((val) => val.trim().replace(/^['"]|['"]$/g, '')); // Remove leading and trailing single or double quotes
      }
      return configObject;
    }, {});
  } catch (error) {
    throw new Error(`Failed to get config: ${error.message}`);
  }
};

export const cmpSlsSync = (token, data) => requestWrapper(
  token,
  config.slsCmpSync,
  'post',
  undefined,
  data,
);

export const postStore = (token, data) => requestWrapper(
  token,
  config.slsStoresResource,
  'post',
  undefined,
  { data },
);

export const putStore = (token, data) => requestWrapper(
  token,
  config.slsStoresResource,
  'put',
  undefined,
  { data }, // should have { data: { old, new } }
);

export const bulkCreateStores = (token, data) => requestWrapper(
  token,
  config.slsStoresBulkCreateResource,
  'post',
  undefined,
  data,
);

export const getStoreVersions = async (token, storeId, anchor, pageSize) => requestWrapper(
  token,
  config.slsStoreVersionsResource,
  'get',
  { anchor, count: pageSize, storeId },
);

/* DIGITAL STORES */

export const getDigitalStoreById = (token, storeId) => requestWrapper(
  token,
  config.slsDigitalStoreResource + storeId,
  'get',
);

export const getDigitalStores = (token) => requestWrapper(
  token,
  config.slsDigitalStoresResource,
  'get',
);

export const postDigitalStore = (token, data) => requestWrapper(
  token,
  config.slsDigitalStoresResource,
  'post',
  undefined,
  { data },
);

export const putDigitalStore = (token, data) => requestWrapper(
  token,
  config.slsDigitalStoresResource,
  'put',
  undefined,
  { data }, // should have { data: { old, new } }
);

/* WAREHOUSES */

export const getWarehouseById = (token, storeId) => requestWrapper(
  token,
  config.slsWarehouseResource + storeId,
  'get',
);

export const getWarehouses = (token) => requestWrapper(
  token,
  config.slsWarehousesResource,
  'get',
);

export const postWarehouse = (token, data) => requestWrapper(
  token,
  config.slsWarehousesResource,
  'post',
  undefined,
  { data },
);

export const putWarehouse = (token, data) => requestWrapper(
  token,
  config.slsWarehousesResource,
  'put',
  undefined,
  { data }, // should have { data: { old, new } }
);

/* STORE OFFERING */

export const getStoreOfferings = (token) => requestWrapper(
  token,
  config.slsStoreOfferingResource,
  'get',
);

export const getStoreOfferingById = (token, storeOfferingId) => requestWrapper(
  token,
  config.slsStoreOfferingIdResource,
  'get',
  { storeOfferingId },
);

export const postStoreOffering = (token, data) => requestWrapper(
  token,
  config.slsStoreOfferingResource,
  'post',
  undefined,
  { data },
);

export const initStoreOfferingAutoTranslation = (token, data) => requestWrapper(
  token,
  config.slsStoreOfferingTranslationResource,
  'post',
  undefined,
  { data },
);

export const putStoreOffering = (token, data) => requestWrapper(
  token,
  config.slsStoreOfferingResource,
  'put',
  undefined,
  { data },
);

export const deleteStoreOffering = (token, storeOfferingId) => requestWrapper(
  token,
  config.slsStoreOfferingResource,
  'delete',
  { storeOfferingId },
);
