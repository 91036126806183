var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StatusIcon } from "../StatusIcon/StatusIcon";
export var Alert = function (_a) {
    var buttonSlot = _a.buttonSlot, children = _a.children, customIcon = _a.customIcon, quiet = _a.quiet, _b = _a.type, type = _b === void 0 ? "neutral" : _b, rest = __rest(_a, ["buttonSlot", "children", "customIcon", "quiet", "type"]);
    var ContentTag = typeof children === "string" ? "p" : "div";
    return (_jsxs("aside", __assign({ role: "note", className: "eds-alert eds-alert--".concat(type, " eds-alert--").concat(quiet ? "quiet" : "dear god refactor me") }, rest, { children: [_jsxs("div", __assign({ className: "eds-alert__left-content" }, { children: [customIcon || (_jsx("div", { children: _jsx(StatusIcon, { statusIconSize: "s", status: type }) })), _jsx(ContentTag, __assign({ className: "eds-alert__content" }, { children: children }))] })), buttonSlot && _jsx("div", __assign({ className: "eds-alert__button-slot" }, { children: buttonSlot }))] })));
};
