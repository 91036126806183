var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { NikeApp } from "@nike/nike-design-system-icons";
import { Link } from "../Link/Link";
import { Text } from "../Text/Text";
import { Tooltip } from "../Tooltip/Tooltip";
import { IconButton } from "../IconButton/IconButton";
export var setActiveValueInNavItems = function (id, items) {
    return items === null || items === void 0 ? void 0 : items.map(function (item) {
        var subItems = item.subItems, keep = __rest(item, ["subItems"]);
        return __assign(__assign({}, keep), { active: item.id === id, subItems: subItems === null || subItems === void 0 ? void 0 : subItems.map(function (subItem) { return (__assign(__assign({}, subItem), { active: subItem.id === id })); }) });
    });
};
var NavItemComponent = function (_a) {
    var item = _a.item, isActive = _a.isActive, handleNavItemClick = _a.handleNavItemClick, handleNavItemHover = _a.handleNavItemHover, panelOpen = _a.panelOpen, visiblePanelId = _a.visiblePanelId, setVisiblePanelId = _a.setVisiblePanelId, setPanelOpen = _a.setPanelOpen;
    return (_jsxs("li", __assign({ className: "eds-icon-button__wrapper" }, { children: [item.subItems && (_jsx(_Fragment, { children: _jsx(IconButton, { className: classNames("eds-icon-button eds-vertical-navbar__icon-button", {
                        "eds-icon-button--active": isActive,
                    }), id: item.id, icon: item.icon, label: item.label, variant: "ghost", onClick: function () {
                        if ((item.subItems.length && !panelOpen) ||
                            (item.subItems.length && visiblePanelId !== item.id)) {
                            setVisiblePanelId(item.id);
                            handleNavItemHover(item);
                        }
                        else if (panelOpen) {
                            handleNavItemClick(item, undefined);
                            setVisiblePanelId(null);
                            setPanelOpen(false);
                        }
                        else {
                            handleNavItemClick(item, undefined);
                        }
                    }, onMouseEnter: function () { return handleNavItemHover(item); } }) })), !item.subItems && (_jsx(Tooltip, __assign({ bodySlot: _jsx(Text, __assign({ font: "subtitle-2" }, { children: item.label })), isDark: true, placement: "right", delay: 0, modifiers: [
                    { name: "offset", options: { offset: [0, 36] } },
                    { name: "flip", enabled: false },
                ] }, { children: _jsx(IconButton, { className: classNames("eds-icon-button eds-vertical-navbar__icon-button", {
                        "eds-icon-button--active": isActive,
                    }), id: item.id, icon: item.icon, label: item.label, variant: "ghost", onClick: function () { return handleNavItemClick(item); }, onMouseEnter: function () { return handleNavItemHover(item); }, onFocus: function () { return handleNavItemHover(item); } }) })))] })));
};
var NavSubItemComponent = function (_a) {
    var item = _a.item, subItem = _a.subItem, handleSubItemClick = _a.handleSubItemClick;
    return (_jsx("li", { children: _jsx(Link, __assign({ className: classNames("eds-vertical-navbar__panel__sub-label", {
                "eds-vertical-navbar__panel__sub-label--active": subItem.active,
            }), as: "button", onClick: function () {
                handleSubItemClick(item, subItem);
            } }, { children: subItem.label })) }));
};
var PanelComponent = function (_a) {
    var panelOpen = _a.panelOpen, visiblePanelId = _a.visiblePanelId, panelData = _a.panelData, handleNavItemClick = _a.handleNavItemClick, appName = _a.appName, appSubtitle = _a.appSubtitle, panelLogoSlot = _a.panelLogoSlot;
    return (_jsx(CSSTransition, __assign({ in: panelOpen, appear: true, timeout: 200, className: "eds-vertical-navbar__panel", classNames: "eds-vertical-navbar__panel--transition", unmountOnExit: true }, { children: _jsx("div", { children: panelOpen && visiblePanelId === (panelData === null || panelData === void 0 ? void 0 : panelData.id) && (_jsxs("div", __assign({ className: "eds-vertical-navbar__panel__inner" }, { children: [_jsx("header", { children: _jsxs("div", __assign({ className: "eds-vertical-navbar__panel__inner-top" }, { children: [panelLogoSlot || _jsx("span", {}), _jsxs("div", { children: [appName && (_jsx(Text, __assign({ as: "h2", font: "title-6" }, { children: appName }))), appSubtitle && (_jsx(Text, __assign({ as: "h3", className: "eds-vertical-navbar__panel__sub-title", font: "subtitle-2" }, { children: appSubtitle })))] })] })) }), _jsx("div", __assign({ className: "eds-vertical-navbar__panel__label" }, { children: _jsx(Link, __assign({ className: "test", onClick: function () {
                                handleNavItemClick(panelData, undefined);
                            }, as: "button" }, { children: panelData === null || panelData === void 0 ? void 0 : panelData.label })) })), _jsx("ul", { children: panelData === null || panelData === void 0 ? void 0 : panelData.subItems.map(function (subItem) { return (_jsx(NavSubItemComponent, { item: panelData, subItem: subItem, handleSubItemClick: handleNavItemClick }, subItem === null || subItem === void 0 ? void 0 : subItem.id)); }) })] }))) }) })));
};
export var VerticalNavbar = function (_a) {
    var className = _a.className, _b = _a.navLogoSlot, navLogoSlot = _b === void 0 ? _jsx(NikeApp, { width: "56px", height: "56px" }) : _b, panelLogoSlot = _a.panelLogoSlot, appName = _a.appName, appSubtitle = _a.appSubtitle, items = _a.items, bottomItems = _a.bottomItems, onNavigate = _a.onNavigate, rest = __rest(_a, ["className", "navLogoSlot", "panelLogoSlot", "appName", "appSubtitle", "items", "bottomItems", "onNavigate"]);
    var _c = useState(false), panelOpen = _c[0], setPanelOpen = _c[1];
    var _d = useState(), panelData = _d[0], setPanelData = _d[1];
    var _e = useState(), visiblePanelId = _e[0], setVisiblePanelId = _e[1];
    var _f = useState(items), updatedItems = _f[0], setUpdatedItems = _f[1];
    var _g = useState(bottomItems || []), updatedBottomItems = _g[0], setUpdatedBottomItems = _g[1];
    useEffect(function () {
        setUpdatedItems(items);
        setUpdatedBottomItems(bottomItems);
    }, [items, bottomItems]);
    useEffect(function () {
        var activeItem = (updatedItems === null || updatedItems === void 0 ? void 0 : updatedItems.find(function (item) { var _a; return item.active || ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.find(function (subItem) { return subItem === null || subItem === void 0 ? void 0 : subItem.active; })); })) ||
            (updatedBottomItems === null || updatedBottomItems === void 0 ? void 0 : updatedBottomItems.find(function (item) { var _a; return item.active || ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.find(function (subItem) { return subItem === null || subItem === void 0 ? void 0 : subItem.active; })); }));
        setPanelData(activeItem);
    }, [updatedItems, updatedBottomItems]);
    var handleNavigate = useCallback(function (item) {
        setUpdatedItems(setActiveValueInNavItems(item.id, updatedItems));
        setUpdatedBottomItems(setActiveValueInNavItems(item.id, updatedBottomItems));
        onNavigate === null || onNavigate === void 0 ? void 0 : onNavigate(item);
    }, [onNavigate, updatedItems, updatedBottomItems]);
    var handleNavItemClick = function (item, subItem) {
        setPanelData(item);
        if (subItem) {
            handleNavigate(subItem);
            setPanelOpen(false);
        }
        else {
            handleNavigate(item);
        }
    };
    var handleNavItemHover = function (item) {
        if (!item.subItems) {
            setPanelOpen(false);
        }
        else {
            setPanelData(item);
            setVisiblePanelId(item.id);
            setPanelOpen(true);
        }
    };
    var handleMouseLeaveVerticalNavbar = function () {
        setTimeout(function () {
            setPanelOpen(false);
        }, 200);
    };
    return (_jsxs("div", __assign({ className: classNames("eds-vertical-navbar eds--dark", className) }, rest, { onMouseLeave: handleMouseLeaveVerticalNavbar }, { children: [_jsxs("div", __assign({ className: "eds-vertical-navbar__strip" }, { children: [_jsx("div", __assign({ className: "eds-icon-logo" }, { children: navLogoSlot })), _jsx("ul", { children: updatedItems.map(function (item) {
                            var _a;
                            var isActive = item.active || ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.some(function (subItem) { return subItem.active; }));
                            return (_jsx(NavItemComponent, { item: item, isActive: isActive, handleNavItemClick: handleNavItemClick, handleNavItemHover: handleNavItemHover, panelOpen: panelOpen, visiblePanelId: visiblePanelId, setVisiblePanelId: setVisiblePanelId, setPanelOpen: setPanelOpen }, item.id));
                        }) }), updatedBottomItems && (_jsx("div", __assign({ className: "eds-vertical-navbar__bottom" }, { children: _jsx("ul", { children: updatedBottomItems.map(function (item) {
                                var _a;
                                var isActive = item.active || ((_a = item.subItems) === null || _a === void 0 ? void 0 : _a.some(function (subItem) { return subItem.active; }));
                                return (_jsx(NavItemComponent, { item: item, isActive: isActive, handleNavItemClick: handleNavItemClick, handleNavItemHover: handleNavItemHover, panelOpen: panelOpen, visiblePanelId: visiblePanelId, setVisiblePanelId: setVisiblePanelId, setPanelOpen: setPanelOpen }, item.id));
                            }) }) })))] })), _jsx(PanelComponent, { panelOpen: panelOpen, visiblePanelId: visiblePanelId, panelData: panelData, handleNavItemClick: handleNavItemClick, appName: appName, appSubtitle: appSubtitle, panelLogoSlot: panelLogoSlot })] })));
};
