var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";
import { Text } from "../Text/Text";
export var Tooltip = function (_a) {
    var children = _a.children, className = _a.className, isDark = _a.isDark, bodySlot = _a.bodySlot, modifiers = _a.modifiers, enableFocus = _a.enableFocus, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, _c = _a.label, label = _c === void 0 ? "Tooltip" : _c, _d = _a.delay, delay = _d === void 0 ? 400 : _d, _e = _a.placement, placement = _e === void 0 ? "bottom" : _e, _f = _a.strategy, strategy = _f === void 0 ? "absolute" : _f, // popper default
    id = _a.id, rest = __rest(_a, ["children", "className", "isDark", "bodySlot", "modifiers", "enableFocus", "isOpen", "label", "delay", "placement", "strategy", "id"]);
    var timeout;
    var _g = useState(null), tooltipElement = _g[0], setTooltipElement = _g[1];
    var _h = useState(null), referenceElement = _h[0], setReferenceElement = _h[1];
    var _j = useState(null), arrowElement = _j[0], setArrowElement = _j[1];
    var _k = useState(false), displayTooltip = _k[0], setDisplayTooltip = _k[1];
    var showTooltip = function () {
        timeout = setTimeout(function () {
            setDisplayTooltip(true);
        }, delay);
    };
    var hideTooltip = function () {
        clearInterval(timeout);
        setDisplayTooltip(false);
    };
    var _l = usePopper(referenceElement, tooltipElement, {
        placement: placement,
        strategy: strategy,
        modifiers: __spreadArray([
            {
                name: "offset",
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: "flip",
                options: {
                    fallbackPlacements: ["bottom", "top"],
                },
            },
            { name: "arrow", options: { element: arrowElement, padding: 8 } }
        ], (modifiers !== null && modifiers !== void 0 ? modifiers : []), true),
    }), styles = _l.styles, attributes = _l.attributes;
    // Accessibility: Close tooltip on escape key press
    useEffect(function () {
        document.addEventListener("keydown", function (e) {
            if (e.key === "Escape") {
                setDisplayTooltip(false);
            }
        });
        return function () {
            document.removeEventListener("keydown", function (e) { return e; });
        };
    }, [displayTooltip]);
    var tootlipClasses = classNames("eds-tooltip", isDark ? "eds--dark" : "eds--light", className);
    var tabIndexProps = enableFocus ? { tabIndex: 0 } : {};
    return (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: "eds-tooltip__anchor", "aria-describedby": "edsTooltip", "aria-label": label }, tabIndexProps, { ref: setReferenceElement, onMouseEnter: showTooltip, onMouseLeave: hideTooltip, onFocus: showTooltip, onBlur: hideTooltip }, { children: React.cloneElement(children, {
                    "aria-haspopup": "dialog",
                }) })), (isOpen || displayTooltip) && (_jsxs("div", __assign({ className: tootlipClasses, role: "dialog", title: label, ref: setTooltipElement, style: styles.popper }, attributes.popper, rest, { children: [_jsx("div", __assign({ className: "eds-tooltip__body", "aria-live": "polite" }, { children: typeof bodySlot === "string" ? (_jsx(Text, __assign({ as: "p", font: "body-3" }, { children: bodySlot }))) : (bodySlot) })), _jsx("div", __assign({ className: "eds-tooltip__arrow", ref: setArrowElement, style: styles.arrow }, attributes.arrow))] })))] }));
};
