var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import classNames from "classnames";
export var Toggle = function (_a) {
    var _b;
    var id = _a.id, onChange = _a.onChange, _c = _a.disabled, disabled = _c === void 0 ? false : _c, className = _a.className, checked = _a.checked, label = _a.label, _d = _a.value, value = _d === void 0 ? "true" : _d, _e = _a.size, size = _e === void 0 ? "medium" : _e, children = _a.children, rest = __rest(_a, ["id", "onChange", "disabled", "className", "checked", "label", "value", "size", "children"]);
    var _f = useState(!!checked), isChecked = _f[0], setIsChecked = _f[1];
    // used to make a controlled component
    useEffect(function () {
        setIsChecked(!!checked);
    }, [checked]);
    var handleChange = function (event) {
        if (disabled === true)
            return;
        if (onChange)
            onChange(event);
        if (typeof checked === "undefined") {
            // uncontrolled
            setIsChecked(!isChecked);
        }
    };
    var containerClass = classNames(className, "eds-toggle", (_b = {},
        _b["eds-toggle--".concat(size)] = size !== "medium",
        _b["eds-toggle--disabled"] = disabled,
        _b));
    return (_jsxs("label", __assign({ htmlFor: id, className: containerClass }, { children: [_jsx("input", __assign({ id: id, type: "checkbox", className: "eds-toggle__input", onChange: handleChange, checked: isChecked, disabled: disabled, value: value }, rest)), _jsx("span", { className: "circle" }), label] })));
};
