import PropTypes from 'prop-types';
import React from 'react';

import BulkCreateStores from './BulkCreateStores';
import BulkEditStores from './BulkEditStores';

const DevOpsTools = ({
 ccmConfig, facilities, userEmail, userToken
}) => (
  <article className="ncss-col-sm-12 ta-sm-l pt12-sm pb6-sm">
    <h1 className="headline-1 pb4-sm text-color-secondary">DevOps Tools</h1>
    <BulkEditStores
      ccmConfig={ccmConfig}
      facilities={facilities}
      userToken={userToken}
    />
    <BulkCreateStores
      ccmConfig={ccmConfig}
      userEmail={userEmail}
      userToken={userToken}
    />
  </article>
);

DevOpsTools.propTypes = {
  ccmConfig: PropTypes.shape().isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
  userEmail: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
};

export default DevOpsTools;
