var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import * as podiumCoreIconsModule from "@nike/nike-design-system-icons";
var podiumCoreIcons = __assign({}, podiumCoreIconsModule);
var podiumCoreIconNames = Object.keys(podiumCoreIcons);
var sizingMap = new Map([
    ["s", 16],
    ["m", 24],
    ["l", 48],
]);
export var Icon = function (_a) {
    var name = _a.name, _b = _a.size, size = _b === void 0 ? "m" : _b, backgroundColor = _a.backgroundColor, backgroundShape = _a.backgroundShape, className = _a.className, enableFocus = _a.enableFocus, color = _a.color, _c = _a["aria-label"], ariaLabel = _c === void 0 ? name : _c, rest = __rest(_a, ["name", "size", "backgroundColor", "backgroundShape", "className", "enableFocus", "color", "aria-label"]);
    var svgSize = sizingMap.get(size);
    var createIconComponent = function () {
        return React.createElement(podiumCoreIcons[name], __assign({ width: svgSize, height: svgSize, className: classNames("eds-icon", "eds-icon--".concat(name.toLowerCase()), className), color: color }, rest));
    };
    // @todo add better error handling if icon string is not in icon array
    if (!podiumCoreIconNames.includes(name))
        return null;
    var tabIndexProps = enableFocus ? { tabIndex: 0 } : {};
    if (backgroundShape) {
        return (_jsx("div", __assign({}, tabIndexProps, { "aria-label": ariaLabel, role: "img", className: classNames("eds-icon__wrapper", "eds-icon__wrapper--".concat(backgroundShape), "eds-icon__wrapper--".concat(size), "eds-icon__wrapper--".concat(name.toLowerCase())), style: {
                backgroundColor: backgroundColor,
            } }, { children: createIconComponent() })));
    }
    return createIconComponent();
};
