var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Label } from "../Label/Label";
import { InputMessageGroup } from "../InputMessageGroup/InputMessageGroup";
export var RadioGroup = function (_a) {
    var label = _a.label, subtitle = _a.subtitle, errorMessage = _a.errorMessage, hasErrors = _a.hasErrors, required = _a.required, orientation = _a.orientation, hideLabel = _a.hideLabel, onChange = _a.onChange, valueSelected = _a.valueSelected, message = _a.message, children = _a.children, className = _a.className, name = _a.name, rest = __rest(_a, ["label", "subtitle", "errorMessage", "hasErrors", "required", "orientation", "hideLabel", "onChange", "valueSelected", "message", "children", "className", "name"]);
    var _b = React.useState(valueSelected), selected = _b[0], setSelected = _b[1];
    React.useEffect(function () {
        setSelected(valueSelected);
    }, [valueSelected]);
    var handleChange = function (e) {
        var _a;
        setSelected((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        if (onChange) {
            onChange(e);
        }
    };
    var getRadios = function () {
        return React.Children.map(children, function (radio) {
            var _a, _b;
            if (!radio)
                return null;
            return React.cloneElement(radio, {
                name: name,
                key: "".concat(name, "-").concat((_a = radio === null || radio === void 0 ? void 0 : radio.props) === null || _a === void 0 ? void 0 : _a.id),
                onChange: handleChange,
                checked: ((_b = radio === null || radio === void 0 ? void 0 : radio.props) === null || _b === void 0 ? void 0 : _b.value) === selected,
                required: required,
                "aria-describedby": "".concat(name, "-meta"),
            });
        });
    };
    return (_jsxs("div", { children: [_jsx(Label, __assign({ id: "".concat(name, "__label"), font: "title-6", screenReaderOnly: hideLabel, hasErrors: hasErrors }, { children: label })), subtitle && (_jsx(Label, __assign({ id: "".concat(name, "__subtitle"), font: "body-3", color: "text-secondary" }, { children: subtitle }))), _jsx("div", __assign({ className: classNames("eds-radio-group", { "eds-radio-group--horizontal": orientation === "horizontal" }, className), role: "radiogroup", "aria-labelledby": "".concat(name, "__label ").concat(name, "__subtitle") }, rest, { children: getRadios() })), (message || hasErrors) && (_jsx(InputMessageGroup, { id: "".concat(name, "-meta"), message: message, errorMessage: hasErrors && (errorMessage || "Error") }))] }));
};
