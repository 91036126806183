var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import classNames from "classnames";
import { Label } from "../Label/Label";
import { InputMessageGroup } from "../InputMessageGroup/InputMessageGroup";
export var CheckboxGroup = function (_a) {
    var label = _a.label, subtitle = _a.subtitle, errorMessage = _a.errorMessage, hasErrors = _a.hasErrors, required = _a.required, orientation = _a.orientation, hideLabel = _a.hideLabel, onChange = _a.onChange, message = _a.message, children = _a.children, className = _a.className, name = _a.name, id = _a.id, rest = __rest(_a, ["label", "subtitle", "errorMessage", "hasErrors", "required", "orientation", "hideLabel", "onChange", "message", "children", "className", "name", "id"]);
    var getCheckboxes = function () {
        return React.Children.map(children, function (checkbox) {
            var _a;
            if (!checkbox)
                return null;
            return React.cloneElement(checkbox, {
                name: name || id,
                key: "".concat(name, "-").concat(id, "-").concat((_a = checkbox === null || checkbox === void 0 ? void 0 : checkbox.props) === null || _a === void 0 ? void 0 : _a.id),
                "aria-describedby": "".concat(id, "-meta"),
                onChange: onChange,
            });
        });
    };
    return (_jsxs("div", { children: [_jsx(Label, __assign({ id: "".concat(id, "__label"), font: "title-6", screenReaderOnly: hideLabel, hasErrors: hasErrors }, { children: label })), subtitle && (_jsx(Label, __assign({ id: "".concat(id, "__subtitle"), font: "body-3", color: "text-secondary" }, { children: subtitle }))), _jsx("div", __assign({ className: classNames("eds-checkbox-group", { "eds-checkbox-group--horizontal": orientation === "horizontal" }, className), "aria-labelledby": "".concat(id, "__label ").concat(id, "__subtitle") }, rest, { children: getCheckboxes() })), (message || hasErrors) && (_jsx(InputMessageGroup, { id: "".concat(id, "-meta"), message: message, errorMessage: hasErrors && (errorMessage || "Error") }))] }));
};
