import { throwInvariant } from './utils';
import Cmd, { isCmd } from './cmd';
export var isLoop = function isLoop(array) {
  return Array.isArray(array) && array.length === 2 && isCmd(array[1]) && !isCmd(array[0]);
};
export var getCmd = function getCmd(loop) {
  return isLoop(loop) ? loop[1] : null;
};
export var getModel = function getModel(loop) {
  return isLoop(loop) ? loop[0] : loop;
};
export var loop = function loop(model, cmd) {
  if (process.env.NODE_ENV === 'development') {
    throwInvariant(isCmd(cmd), 'Given cmd is not an Cmd instance.');
  }

  return [model, cmd];
};
export var liftState = function liftState(state) {
  return isLoop(state) ? state : loop(state, Cmd.none);
};