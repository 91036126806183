var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Close } from "@nike/nike-design-system-icons";
import FocusTrap from "focus-trap-react";
import { Button } from "../Button/Button";
import useOnClickOutside from "../../utils/useOnClickOutsideHook";
import { useNoScrollBody } from "../../utils/useBodyNoScroll";
export var SidePanel = function (_a) {
    var className = _a.className, children = _a.children, headerSlot = _a.headerSlot, footerSlot = _a.footerSlot, isOpen = _a.isOpen, onDismiss = _a.onDismiss, _b = _a.hasScrim, hasScrim = _b === void 0 ? false : _b, displayCheckOverride = _a.displayCheckOverride, hideFade = _a.hideFade, rest = __rest(_a, ["className", "children", "headerSlot", "footerSlot", "isOpen", "onDismiss", "hasScrim", "displayCheckOverride", "hideFade"]);
    var _c = React.useState(0), timeOut = _c[0], setTimeOut = _c[1];
    var _d = React.useState({
        top: false,
        mid: false,
        bottom: false,
    }), scrollPosition = _d[0], setScrollPosition = _d[1];
    var contentContainerRef = React.useRef(null);
    // Check for overflow on initial render
    React.useEffect(function () {
        if (contentContainerRef.current) {
            var _a = contentContainerRef.current, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            var hasOverflow = scrollHeight > clientHeight;
            setScrollPosition({
                top: hasOverflow,
                mid: false,
                bottom: false,
            });
        }
    }, [isOpen]);
    // Update scrollPosition. Only active when scroll exists
    var onScroll = function () {
        if (contentContainerRef.current) {
            var _a = contentContainerRef.current, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            var isBottom = Math.round(scrollTop) + Math.round(clientHeight) ===
                Math.round(scrollHeight);
            var isTop = scrollTop === 0;
            var isMid = !isBottom && !isTop;
            setScrollPosition({
                top: isTop,
                mid: isMid,
                bottom: isBottom,
            });
        }
    };
    var sidePanelRef = useRef(null);
    var _e = useState(false), openHandler = _e[0], setOpenHandler = _e[1];
    var handleSlideOut = function () {
        setOpenHandler(false);
        setTimeout(function () { return onDismiss(); }, 400);
    };
    var handleClickOutside = useCallback(function (event) {
        var target = event.target;
        if (target.classList.contains("eds-scrim")) {
            handleSlideOut();
        }
    }, [onDismiss]);
    useOnClickOutside({
        ref: sidePanelRef,
        handler: handleClickOutside,
        isActive: isOpen,
        dep: [sidePanelRef],
    });
    useEffect(function () {
        if (isOpen) {
            setOpenHandler(true);
        }
        if (!isOpen) {
            setOpenHandler(false);
        }
    }, [openHandler, isOpen]);
    // stop body scroll on isOpen
    useNoScrollBody(isOpen);
    return (_jsx(FocusTrap, __assign({ active: hasScrim && isOpen, focusTrapOptions: {
            escapeDeactivates: false,
            tabbableOptions: {
                displayCheck: displayCheckOverride ? "none" : "full",
            },
        } }, { children: _jsx("aside", __assign({ className: classNames("eds-scrim", { "eds-scrim--notShown": !hasScrim }, { "eds-scrim--hidden": !isOpen }, className), "aria-hidden": !isOpen, onKeyDown: function (e) { return e.key === "Escape" && handleSlideOut(); } }, { children: _jsx("div", __assign({ ref: sidePanelRef }, rest, { children: _jsx(CSSTransition, __assign({ in: openHandler, timeout: timeOut, className: classNames("eds-side-panel", className), classNames: "eds-side-panel--transition", onEnter: function () { return setTimeOut(400); }, onExit: function () { return setTimeOut(400); } }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ className: "eds-side-panel__header-container" }, { children: [typeof headerSlot === "string" ? (_jsx("h2", __assign({ className: "eds-side-panel__header" }, { children: headerSlot }))) : (_jsx("span", __assign({ className: "eds-side-panel__header" }, { children: headerSlot }))), _jsxs(Button, __assign({ variant: "ghost", size: "small", onClick: function () {
                                            handleSlideOut();
                                        }, className: "eds-side-panel__close" }, { children: [_jsx(Close, {}), _jsx("span", __assign({ className: "eds-hidden--screen-reader-only" }, { children: "Close Side Panel" }))] }))] })), _jsx("div", __assign({ className: "eds-side-panel__content" }, { children: _jsxs("div", __assign({ ref: contentContainerRef, onScroll: onScroll }, { children: [!hideFade && (_jsx("span", { className: scrollPosition.bottom || scrollPosition.mid
                                                ? "eds-side-panel__top-fade eds-fade-in"
                                                : "eds-side-panel--hide" })), children, !hideFade && (_jsx("span", { className: scrollPosition.top || scrollPosition.mid
                                                ? "eds-side-panel__bottom-fade eds-fade-in"
                                                : "eds-side-panel--hide" }))] })) })), footerSlot && (_jsxs(_Fragment, { children: [_jsx("div", { className: "eds-side-panel__divider" }), _jsx("div", { children: footerSlot })] }))] }) })) })) })) })));
};
