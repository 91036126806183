var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { RadioIcon } from "@nike/nike-design-system-icons";
import classNames from "classnames";
export var Radio = function (_a) {
    var checked = _a.checked, disabled = _a.disabled, id = _a.id, label = _a.label, name = _a.name, onChange = _a.onChange, value = _a.value, className = _a.className, rest = __rest(_a, ["checked", "disabled", "id", "label", "name", "onChange", "value", "className"]);
    var _b = useState(!!checked), isChecked = _b[0], setIsChecked = _b[1];
    // used to make a controlled component
    useEffect(function () {
        setIsChecked(!!checked);
    }, [checked]);
    var handleChange = function (event) {
        if (disabled !== true) {
            setIsChecked(!isChecked);
            if (onChange)
                onChange(event);
        }
    };
    return (_jsxs("label", __assign({ htmlFor: id, className: classNames(className, "eds-radio", {
            "eds-radio--disabled": disabled,
        }), style: typeof label !== "string" ? { alignItems: "start" } : null }, { children: [_jsx("input", __assign({ type: "radio", name: name, id: id, value: value || id, checked: checked, disabled: !!disabled, onChange: handleChange, className: "eds-radio__input" }, rest)), _jsx("span", { className: "eds-radio__animation" }), _jsx(RadioIcon, { "aria-hidden": true }), label] })));
};
